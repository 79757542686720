import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BASE_URL, newBackend } from "../../comm/API";
import Masks from "../../comm/masks";
import AtendimentoAlert from "../../components/AlertAtendimento/alertAtendimento";
import AlertBrasilMaisProdutivo from "../../components/alertBrasilMaisProdutivo/alertBrasilMaisProdutivo";
import GenericButton from "../../components/genericButton/genericButton";
import Trilha from "../brasilMais/trilha";
import TrilhaMentoriaDigital from "../brasilMais/trilhaMentoriaDigital";
import Metas from "./../solicitacoesDN/metas";
import MetasGoverno from "./../solicitacoesDN/metasGoverno";
import Anexos from "./anexos";
import "./atendimento.scss";
import ChangeStatus from "./changeStatus";
import Comentario from "./comentario";
import ControleBrasilMais from "./controleBrasilMais";
import Entrega from "./entrega";
import { produtoNacionalBMPEnum } from "./enum/produtoNacionalBMP";
import Evidencia from "./evidencia";
import EvidenciaDigital from "./evidenciaDigital";
import Fase from "./fase";
import Indicadores from "./indicadores";
import Participante from "./participante";
import PesquisaSatisfacao from "./pesquisaSatisfacao";
import Producao from "./producao";
import Prospeccao from "./prospeccao";
import Recurso from "./recurso";
import RegistroAnexos from "./registroAnexos";
import RegistroIndicadores from "./registroIndicadores";
import RegistroIndicadoresNovo from "./registroIndicadoresNovo";
import Relatorio from "./relatorio";
import Situacao from "./situacao";
import Status from "./status";
import TermoAceite from "./termoAceite";

export default class Resumo extends Component {
  modalFase = false;
  modalComentario = false;
  modalProducao = false;
  modalRecurso = false;
  modalEntrega = false;
  modalWarningStatus = false;
  modalConfirmaStatus = false;
  modalIndicadores = false;
  modalPesquisa = false;
  modalTrilha = false;
  modalProspeccao = false;
  modalBrasilMais = false;
  indicadoresScreen = false;
  anexosScreen = false;
  isBaseNacional = this.props.atendimento.produtoRegional.produtoNacional.isBaseNacional;

  constructor(props) {
    super(props);
    this.state = {
      solicitacaoStatusList: this.getSolicitacaoStatus(),
      clienteBackNovo: null,
      isMatchingProdutoNacional: false,
      validaPorte: false,
      indicadores: null,
      anexosIndicadoresListNovo: null,
      loadingAnexos: false,
    };
  }

  getClienteBackendNovo = async () => {
    try {
      const res = await newBackend.get(`/cliente/${this.props.atendimento.cliente.id}`);
      const cliente = res.data;
      return cliente;
    } catch (error) {
      console.log(error);
    }
  };

  getIndicadoresBackNovo = async () => {
    try {
      const res = await newBackend.get(`/programa-base-nacional/${this.props.atendimento.id}`);
      const indicadores = res.data.indicadores;
      return indicadores;
    } catch (error) {
      console.log(error);
    }
  };

  getAnexoIndicadoresBackNovo = async () => {
    try {
      const res = await newBackend.get(`/anexo-atendimento-referencial/${this.props.atendimento.id}`);
      const anexos = res.data;

      const anexosFiltrados = anexos.reduce((acc, anexo) => {
        acc[anexo.anexoReferencial.id] = anexo;
        return acc;
      }, {});
      return Object.values(anexosFiltrados).map(({ versao, ...rest }) => rest);
    } catch (error) {
      console.log(error);
    }
  };

  
  atualizarAnexos = async () => {
    try {
      this.setState({ loadingAnexos: true });
      const anexosIndicadoresListNovo = await this.getAnexoIndicadoresBackNovo();
      this.setState({
        anexosIndicadoresListNovo: anexosIndicadoresListNovo  || [],
        loadingAnexos: false,
      });
    } catch (error) {
      console.log("Erro ao atualizar anexos:", error);
      this.setState({ loadingAnexos: false });
    }
  };

  async componentDidMount() {
    try {
      this.setState({loadingAnexos: true})

      const indicadores = await this.getIndicadoresBackNovo();
      const clienteBackNovo = await this.getClienteBackendNovo();
      const anexosIndicadoresListNovo = await this.getAnexoIndicadoresBackNovo();
      const idProdutoNacional = this.props.atendimento.produtoRegional.produtoNacional.id;
      let isMatchingProdutoNacional;
      if (
        [
          produtoNacionalBMPEnum.eficienciaEnergetica,
          produtoNacionalBMPEnum.manufaturaEnxuta,
          produtoNacionalBMPEnum.smartFactoryPiloto,
          produtoNacionalBMPEnum.transformacaoFase1Piloto,
          produtoNacionalBMPEnum.transformacaoFase2Piloto,
          produtoNacionalBMPEnum.smartFactory,
        ].includes(idProdutoNacional)
      ) {
        isMatchingProdutoNacional = true;
      }

      const atualizarContato = !clienteBackNovo.contatos || !clienteBackNovo.contatos.some(contato => contato.cpf);
      const atualizarClassificacao = !clienteBackNovo.tipocnpj || clienteBackNovo.tipocnpj.id !== 3;
      const mostrarAlerta = clienteBackNovo.porte_cliente_rais === null || clienteBackNovo.porte_cliente_faturamento === null || atualizarClassificacao || atualizarContato;

      this.setState({
        anexosIndicadoresListNovo: anexosIndicadoresListNovo  || [],
        loadingAnexos: false,
      });

      if (isMatchingProdutoNacional) {
        this.setState({
          clienteBackNovo,
          validaPorte: !mostrarAlerta,
          isMatchingProdutoNacional,
          indicadores,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  getSumAtendimentoTotal = () => {
    // TODO: Verificar se o cálculo de fato é esse
    // var rtno = this.props.atendimento.numeroDeReceitaEstimada + this.props.atendimento.vlrEconomico;
    const rtno = this.props.atendimento.previsaoReceitaList.reduce((acc, current) => {
      return acc + current.valor + current.economico;
    }, 0);
    return rtno;
  };

  getSolicitacaoStatus() {
    return [
      { chave: "analise", descricao: "Em Análise", checked: false, ids: [1, 4, 7, 10, 13, 16, 19], group: "grey" },
      { chave: "conforme", descricao: "Conforme", checked: false, ids: [2, 5, 8, 11, 14, 17, 20] },
      { chave: "naoconforme", descricao: "Não Conforme", checked: false, ids: [3, 6, 9, 12, 15, 18, 21] },
    ];
  }

  getPrevisaoReceitaDescription = (previsaoReceita) => {
    switch (previsaoReceita.tipoPrevisaoReceita.id) {
      case 1: //MERCADO
      case 2: //FORMENTO
        return previsaoReceita.fontePagadora.cpfcnpj + " - " + previsaoReceita.fontePagadora.nome;
      case 3: //SENAI
        return "Senai";
      case 5: //ICT
        return previsaoReceita.ictParceiro.descricao;
      case 7: //AGENCIA NACIONAL
        return previsaoReceita.agenciaNacional.descricao;
      case 8: //AGENCIA DE FORMETO
        return previsaoReceita.agenciaFomento.linhaFomento + " - " + previsaoReceita.agenciaFomento.descricao;
      case 10: //UO DO ATENDIMENTO
      case 11: //DR DO ATENDIMENTO
      case 12: //SENAI PARCEIRO
        return previsaoReceita.unidade.cnpj + " - " + previsaoReceita.unidade.descricao;
      case 13: //SISTEMA INDÚSTRIA
      case 14: //SESI/SENAI DN
        if (previsaoReceita.sistemaIndustria != null) {
          return previsaoReceita.sistemaIndustria.cpfcnpj + " - " + previsaoReceita.sistemaIndustria.nome;
        } else {
          return previsaoReceita.fontePagadora.cpfcnpj + " - " + previsaoReceita.fontePagadora.nome;
        }
      default:
        return <label></label>;
    }
  };

  getPrevisaoReceitaSum = (previsaoReceita) => {
    if (typeof previsaoReceita.valor === "undefined" || previsaoReceita.valor === null) {
      previsaoReceita.valor = 0;
    }
    if (typeof previsaoReceita.economico === "undefined" || previsaoReceita.economico === null) {
      previsaoReceita.economico = 0;
    }
    var rtno = previsaoReceita.valor + previsaoReceita.economico;
    return rtno;
  };

  getLabelProducaoPrevista = () => {
    //Relatórios ou Ensaios
    var rtno = "Relatórios";
    try {
      if (typeof this.props.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeProducaoEstimada !== "undefined") {
        if (this.props.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeProducaoEstimada !== "") {
          rtno = this.props.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeProducaoEstimada;
        }
      }
    } catch (e) {
      // do nothing
    }
    return rtno;
  };

  getLabelNumeroRelatorio = () => {
    //Certificados ou Calibrações
    var rtno = "Certificados";
    try {
      if (typeof this.props.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeRelatorioEstimado !== "undefined") {
        if (this.props.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeRelatorioEstimado !== "") {
          rtno = this.props.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeRelatorioEstimado;
        }
      }
    } catch (e) {
      // do nothing
    }
    return rtno;
  };

  openIndicadoresScreen = (e) => {
    this.indicadoresScreen = e;
    this.setState(this.state);
  };

  openAnexosScreen = (e) => {
    this.anexosScreen = e;
    this.setState(this.state);
  };

  downloadAnexosAtendimento = () => {
    this.props.showLoading();
    window.open(BASE_URL + "rest/gestao/Atendimento/download/" + this.props.atendimento.id);
    this.props.closeLoading();
  };

  showDownloadAnexosButton = () => {
    return (
      this.props.atendimento &&
      ((this.props.atendimento.proposta && this.props.atendimento.proposta.anexoPropostaList && this.props.atendimento.proposta.anexoPropostaList.length > 0) ||
        (this.props.atendimento.anexoIndicadorProdutividadeList && this.props.atendimento.anexoIndicadorProdutividadeList > 0) ||
        (this.props.atendimento.termoAceiteList && this.props.atendimento.termoAceiteList.length > 0) ||
        (this.props.atendimento.anexoAtendimentoReferencialList && this.props.atendimento.anexoAtendimentoReferencialList.length > 0))
    );
  };

  formatDataWithSlash(date) {
    if (date != null) {
      var dataList = date.split("T");
      var dataAux = dataList[0].split("-");
      var data = dataAux[2] + "/" + dataAux[1] + "/" + dataAux[0];
      return data;
    }
  }

  formatDataTimestamp(timestamp) {
    var data = new Date(timestamp).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" });
    let dataList = data.split(" ")[0].split("/");
    return dataList[0] + "/" + dataList[1] + "/" + dataList[2];
  }

  hasValue(campo) {
    return campo !== undefined && campo !== null && campo !== "";
  }

  changeModal = (modal, status) => {
    this[modal] = status;
    if (modal === "modalFase" && status) {
      this.atendimentoFase = { fase: null, dataFinal: null, quantidadeDeHoras: 0 };
    }
    this.setState(this.state);
  };

  changeModalShowToast = (modal, status, msg) => {
    this[modal] = status;
    if (modal === "modalFase" && status) {
      this.atendimentoFase = { fase: null, dataFinal: null, quantidadeDeHoras: 0 };
    }
    this.setState(this.state);
    this.props.showToast(msg, "success");
  };

  closeTrilha = () => {
    this.changeModal("modalTrilha", false);
    this.props.closeLoading();
  };

  render() {
    const btnVoltar = <GenericButton color={"darkBlue-outline"} label={"Voltar"} subClass={"basic-button"} click={() => this.props.clearAtendimento()} />;
    let { validaPorte, isMatchingProdutoNacional, clienteBackNovo } = this.state;
    const idProdutoNacional = this.props.atendimento.produtoRegional.produtoNacional.id;
    if ([produtoNacionalBMPEnum.eficienciaEnergetica, produtoNacionalBMPEnum.manufaturaEnxuta, produtoNacionalBMPEnum.TransformacaoPiloto].includes(idProdutoNacional)) {
      isMatchingProdutoNacional = true;
    }

    return (
      <Grid container>
        {!this.indicadoresScreen && !this.anexosScreen && !this.modalTrilha ? (
          <Grid container spacing={3} className={"atendimento"}>
            <Grid item xs={12} className={"resumo"}>
              <Grid container>
                <AtendimentoAlert atendimento={this.props.atendimento} />
                {!validaPorte ? (
                  <>
                    <AlertBrasilMaisProdutivo cliente={clienteBackNovo} />
                  </>
                ) : null}
                <Grid item xs={12}>
                  <h1>Resumo do atendimento</h1>
                </Grid>
                {this.props.showButton && (
                  <Grid item xs={7} style={{ display: "flex", alignItems: "center", paddingTop: "8px", paddingBottom: "8px" }}>
                    {!this.props.isDN && (this.props.isPronto || this.props.isAceito || this.props.isNegociacao || this.props.isExecucao) ? (
                      <Link to={`/cad/atendimento/atendimento/novo/${this.props.atendimento.id}`}>
                        <GenericButton color={"darkBlue"} label={"Editar atendimento"} subClass={"basic-button"} />
                      </Link>
                    ) : null}
                    {!this.props.isDN && this.props.atendimento.proposta != null && !this.props.isArquivado && !this.props.isPronto && (
                      <GenericButton color={"darkBlue-outline"} subClass={"basic-button"} label={"Visualizar proposta"} click={() => this.props.changeModal("modalVisualizarProposta", true)} />
                    )}
                    {((!this.props.isAtendimentoDIGITAL && !this.props.isDN && !this.props.atendimento.proposta && this.props.isPronto) ||
                      (this.props.isAtendimentoDIGITAL &&
                        (this.props.atendimento.cliente.clienteABDI != null ||
                          this.props.atendimento.cliente.clienteABDIDigital != null ||
                          (this.props.atendimento.cliente.clienteABDI == null &&
                            this.props.atendimento.cliente.clienteABDIDigital == null &&
                            this.props.atendimento.solicitacaoStatus != null &&
                            this.props.atendimento.solicitacaoStatus.descricao === "Conforme")))) && (
                      <GenericButton color={"darkBlue-outline"} subClass={"basic-button"} label={"Registrar proposta"} click={() => this.props.changeModal("modalRegistrarProposta", true)} />
                    )}
                  </Grid>
                )}
                <Grid item xs={12} style={{ paddingTop: "8px" }}>
                  <ChangeStatus
                    idProdutoBrasilProdutivo={this.props.idProdutoBrasilProdutivo}
                    idProdutoBrasilProdutivoIPEA={this.props.idProdutoBrasilProdutivoIPEA}
                    idProdutoBrasilProdutivoIE={this.props.idProdutoBrasilProdutivoIE}
                    idProdutoBrasilProdutivoLEAN28={this.props.idProdutoBrasilProdutivoLEAN28}
                    idProdutoBrasilProdutivoLEAN56={this.props.idProdutoBrasilProdutivoLEAN56}
                    idProdutoBrasilProdutivoMENTORIA={this.props.idProdutoBrasilProdutivoMENTORIA}
                    idConsultoriaManufatura180={this.props.idConsultoriaManufatura180}
                    idProdutoIndustriaMaisAvancada={this.props.idProdutoIndustriaMaisAvancada}
                    idMentoriaDigital={this.props.idMentoriaDigital}
                    formatData={this.props.formatData}
                    isDN={this.props.isDN}
                    isAceito={this.props.isAceito}
                    isNegociacao={this.props.isNegociacao}
                    isPronto={this.props.isPronto}
                    isExecucao={this.props.isExecucao}
                    isArquivado={this.props.isArquivado}
                    canChangeStatus={this.props.canChangeStatus}
                    changeModal={this.changeModal}
                    openAtendimento={this.props.openAtendimento}
                    atendimento={this.props.atendimento}
                    usuario={this.props.usuario}
                    indicadores={this.props.indicadores}
                    isAtendimentoBP={this.props.isAtendimentoBP}
                    isAtendimentoIA={this.props.isAtendimentoIA}
                    isAtendimentoIA180={this.props.isAtendimentoIA180}
                    isAtendimentoIE={this.props.isAtendimentoIE}
                    isAtendimentoIPEA={this.props.isAtendimentoIPEA}
                    isAtendimentoLEAN={this.props.isAtendimentoLEAN}
                    isAtendimentoLEAN28={this.props.isAtendimentoLEAN28}
                    isAtendimentoLEAN56={this.props.isAtendimentoLEAN56}
                    isAtendimentoMENTORIA={this.props.isAtendimentoMENTORIA}
                    isAtendimentoDIGITAL={this.props.isAtendimentoDIGITAL}
                    isAtendimentoBrasilMaisLEAN={this.props.isAtendimentoBrasilMaisLEAN}
                    modalConfirmaStatus={this.modalConfirmaStatus}
                    modalWarningStatus={this.modalWarningStatus}
                    editarSolicitacaoStatus={this.props.editarSolicitacaoStatus}
                    solicitacaoStatusList={this.state.solicitacaoStatusList}
                    showToast={this.props.showToast}
                    changeModalShowToast={this.changeModalShowToast}
                    showLoading={this.props.showLoading}
                    closeLoading={this.props.closeLoading}
                    changeStatus={this.props.changeStatus}
                    turma={this.props.turma}
                    atualizarAtendimentos={this.props.atualizarAtendimentos}
                    refreshAtendimento={this.props.refreshAtendimento}
                    gerenciarAtendimento={this.props.gerenciarAtendimento}
                    isModuloNacional={this.props.isModuloNacional}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <h3>Informações gerais</h3>
                </Grid>
                <Grid item xs={4}>
                  <b>Título</b>
                </Grid>
                <Grid item xs={8}>
                  {this.props.atendimento.titulo}
                </Grid>
                <Grid item xs={4}>
                  <b>ID</b>
                </Grid>
                <Grid item xs={8}>
                  {this.props.atendimento.id}
                </Grid>
                {this.props.atendimento.numero != null ? (
                  <Grid item xs={4}>
                    <b>Número</b>
                  </Grid>
                ) : null}
                {this.props.atendimento.numero != null ? (
                  <Grid item xs={8}>
                    {this.props.atendimento.numero}
                  </Grid>
                ) : null}
                {this.props.atendimento.proposta != null ? (
                  <Grid item xs={4}>
                    <b>Proposta</b>
                  </Grid>
                ) : null}
                {this.props.atendimento.proposta != null ? (
                  <Grid item xs={8}>
                    {this.props.atendimento.proposta.descricao}
                  </Grid>
                ) : null}
                {this.props.atendimento.produtoRegional.produtoNacional.descricao != null ? (
                  <Grid item xs={4}>
                    <b>Produto Nacional</b>
                  </Grid>
                ) : null}
                <Grid item xs={8}>
                  {this.props.atendimento.produtoRegional.produtoNacional.descricao}
                </Grid>
                {this.props.atendimento.produtoRegional != null ? (
                  <Grid item xs={4}>
                    <b>Produto Regional</b>
                  </Grid>
                ) : null}
                <Grid item xs={8}>
                  {this.props.atendimento.produtoRegional.nome}
                </Grid>
                <Grid item xs={4}>
                  <b>Departamento Regional</b>
                </Grid>
                <Grid item xs={8}>
                  {this.props.atendimento.regional.abreviacao}
                </Grid>
                <Grid item xs={4}>
                  <b>Unidade Operacional</b>
                </Grid>
                <Grid item xs={8}>
                  {this.props.atendimento.unidade.abreviacao}
                </Grid>
                <Grid item xs={4}>
                  <b>Datas previstas</b>
                </Grid>
                <Grid item xs={8}>
                  <span style={{ marginRight: "16px" }}>Início: {this.props.atendimento.dataInicioPrevista != null ? this.formatDataWithSlash(this.props.atendimento.dataInicioPrevista) : "-"}</span>
                  <span>Término: {this.props.atendimento.dataConclusaoPrevista != null ? this.formatDataWithSlash(this.props.atendimento.dataConclusaoPrevista) : "-"}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <h3>Descritivo</h3>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    <b>Resumo executivo</b>
                  </p>
                  <p>{this.props.atendimento.resumoExecutivo != null ? this.props.atendimento.resumoExecutivo : "-"}</p>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <h3>Empresa atendida</h3>
                </Grid>
                <Grid item xs={5}>
                  <b>Nome fantasia</b>
                </Grid>
                <Grid item xs={7}>
                  {this.props.atendimento.cliente.nome}
                </Grid>
                <Grid item xs={5}>
                  <b>Razão social</b>
                </Grid>
                <Grid item xs={7}>
                  {this.props.atendimento.cliente.razaoSocial}
                </Grid>
                {this.props.atendimento.cliente.cpfcnpj ? 
                (
                  <>
                    <Grid item xs={5}>
                      <b>CPF ou CNPJ</b>
                    </Grid>
                    <Grid item xs={7}>
                      {this.props.atendimento.cliente.cpfcnpj}
                    </Grid>
                  </>
                ) : null }
                <Grid item xs={5}>
                  <b>Setor industrial do cliente atendido</b>
                </Grid>
                <Grid item xs={7}>
                  {this.props.atendimento.setor != null ? this.props.atendimento.setor.descricao : "-"}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <h3>Receita</h3>
                </Grid>
                {this.props.atendimento.previsaoReceitaList.map((previsaoReceita, index) =>
                  previsaoReceita ? (
                    <Grid container key={index} className={"container-info"}>
                      <Grid item xs={12}>
                        <b>
                          {index < 10 ? "0" + (index + 1) : index + 1} Fonte pagadora | {previsaoReceita.tipoPrevisaoReceita.descricao}
                        </b>
                      </Grid>
                      <Grid item xs={9} className={"label-info"}>
                        {this.getPrevisaoReceitaDescription(previsaoReceita)}
                      </Grid>
                      <Grid item xs={3} className={"label-info"}>
                        {Masks.maskCurrency(this.getPrevisaoReceitaSum(previsaoReceita))}
                      </Grid>
                      <br></br>
                      <br></br>
                    </Grid>
                  ) : null
                )}
                <Grid item xs={9} className={"label-info"}>
                  <b>Valor total do projeto</b>
                </Grid>
                <Grid item xs={3} className={"label-info"}>
                  <b>{this.props.atendimento.isValorHora ? "indefinida" : Masks.maskCurrency(this.getSumAtendimentoTotal())}</b>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <h3>Produção</h3>
                </Grid>
                {this.props.isMetrologia ? (
                  <Grid container>
                    <Grid item xs={6} className={"label-info"}></Grid>
                    <Grid item xs={3} className={"label-info"}>
                      {this.getLabelProducaoPrevista()}
                    </Grid>
                    <Grid item xs={3} className={"label-info"}>
                      {this.getLabelNumeroRelatorio()}
                    </Grid>

                    <Grid item xs={6} className={"label-info"}>
                      <b>Total</b>
                    </Grid>
                    <Grid item xs={3} className={"label-info"}>
                      <b>{this.props.atendimento.isEscopoIndefinido ? "indefinido" : this.props.atendimento.numeroDeProducaoEstimada}</b>
                    </Grid>
                    <Grid item xs={3} className={"label-info"}>
                      <b>{this.props.atendimento.isEscopoIndefinido ? "indefinido" : this.props.atendimento.numeroDeRelatorioEstimado}</b>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid container className={"container-info"}>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={3} className={"label-info"}>
                        Horas previstas
                      </Grid>
                      <Grid item xs={3} className={"label-info"}>
                        Receita prevista
                      </Grid>
                    </Grid>

                    {this.props.atendimento.previsaoProducaoEmRedeList.map((previsaoProducao, index) =>
                      previsaoProducao ? (
                        <Grid container key={"previsaoReceitaRede" + index} className={"container-info"}>
                          <Grid item xs={6} className={"label-info"}>
                            {previsaoProducao.unidade?.abreviacao}
                          </Grid>
                          <Grid item xs={3} className={"label-info"}>
                            {previsaoProducao.numeroDeProducaoEstimada} horas
                          </Grid>
                          <Grid item xs={3} className={"label-info"}>
                            {Masks.maskCurrency(previsaoProducao.numeroDeReceitaEstimada)}
                          </Grid>
                        </Grid>
                      ) : null
                    )}
                    {this.props.atendimento.previsaoReceitaList.map((previsaoReceita, index) =>
                      previsaoReceita && previsaoReceita.tipoPrevisaoReceita.id === 5 && previsaoReceita.isParceiro ? (
                        <Grid container key={"previsaoReceita" + index} className={"container-info"}>
                          <Grid item xs={6} className={"label-info"}>
                            {previsaoReceita.ictParceiro.descricao}
                          </Grid>
                          <Grid item xs={3} className={"label-info"}>
                            0 horas
                          </Grid>
                          <Grid item xs={3} className={"label-info"}>
                            {Masks.maskCurrency(previsaoReceita.receitarepassada)}
                          </Grid>
                        </Grid>
                      ) : previsaoReceita && previsaoReceita.tipoPrevisaoReceita.id === 9 && previsaoReceita.isParceiro ? (
                        <Grid container key={"previsaoReceita" + index} className={"container-info"}>
                          <Grid item xs={6} className={"label-info"}>
                            {previsaoReceita.agenciaBolsa.descricao}
                          </Grid>
                          <Grid item xs={3} className={"label-info"}>
                            0 horas
                          </Grid>
                          <Grid item xs={3} className={"label-info"}>
                            {Masks.maskCurrency(previsaoReceita.receitarepassada)}
                          </Grid>
                        </Grid>
                      ) : (
                        <div key={index}></div>
                      )
                    )}

                    <Grid item xs={6} className={"label-info"}>
                      <b>Total</b>
                    </Grid>
                    <Grid item xs={3} className={"label-info"}>
                      <b>{this.props.atendimento.numeroDeProducaoEstimada} horas</b>
                    </Grid>
                    <Grid item xs={3} className={"label-info"}>
                      <b>{Masks.maskCurrency(this.props.atendimento.numeroDeReceitaEstimada)}</b>
                    </Grid>
                  </Grid>
                )}

                {(this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL) && !this.props.isPronto && !this.props.isArquivado && (
                  <Grid container className={"dados-complementares-item"}>
                    <Grid item xs={12} style={{ display: "flex" }}>
                      <h1>Dados complementares</h1>
                      <label className={"label-obrigatorio"}>(obrigatório)</label>
                    </Grid>
                    <Grid item xs={12}>
                      <TermoAceite
                        atendimento={this.props.atendimento}
                        isNegociacao={this.props.isNegociacao}
                        changeModal={this.changeModal}
                        showToast={this.props.showToast}
                        showLoading={this.props.showLoading}
                        closeLoading={this.props.closeLoading}
                        changeModalShowToast={this.changeModalShowToast}
                        isDN={this.props.isDN}
                        isAtendimentoDIGITAL={this.props.isAtendimentoDIGITAL}
                        refreshAtendimento={this.props.refreshAtendimento}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Participante
                        atendimentoTurma={this.props.atendimentoTurma}
                        turma={this.props.turma}
                        atendimento={this.props.atendimento}
                        isNegociacao={this.props.isNegociacao}
                        changeModal={this.changeModal}
                        showToast={this.props.showToast}
                        showLoading={this.props.showLoading}
                        closeLoading={this.props.closeLoading}
                        changeModalShowToast={this.changeModalShowToast}
                        isDigital={this.props.isAtendimentoDIGITAL}
                        isDN={this.props.isDN}
                      />
                    </Grid>
                    {!this.props.isNegociacao && !this.props.isAceito && (
                      <Grid item xs={12}>
                        <Relatorio
                          turma={this.props.turma}
                          atendimento={this.props.atendimento}
                          isConcluido={this.props.isConcluido}
                          changeModal={this.changeModal}
                          showToast={this.props.showToast}
                          showLoading={this.props.showLoading}
                          closeLoading={this.props.closeLoading}
                          changeModalShowToast={this.changeModalShowToast}
                          isDN={this.props.isDN}
                          refreshAtendimento={this.props.refreshAtendimento}
                          isAtendimentoDIGITAL={this.props.isAtendimentoDIGITAL}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className={"acompanhamento"}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <h1>Acompanhamento</h1>
                </Grid>
                {this.showDownloadAnexosButton() && (
                  <Grid item xs={4}>
                    <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "20px" }}>
                      <GenericButton color={"darkBlue-outline"} label={"Baixar anexos"} subClass={"basic-button"} click={this.downloadAnexosAtendimento} />
                    </div>
                  </Grid>
                )}
                <Status
                  atendimento={this.props.atendimento}
                  isDN={this.props.isDN}
                  atendimentoJustificativa={this.props.atendimentoJustificativa}
                  isAtendimentoBrasilMaisLEAN={this.props.isAtendimentoBrasilMaisLEAN}
                  formatDataWithSlash={this.formatDataWithSlash}
                />
                {this.props.isDN && <Situacao atendimento={this.props.atendimento} />}
                {this.props.gerenciarAtendimento && (this.props.isAtendimentoBP || this.props.isAtendimentoIPEA) && (
                  <Grid item xs={6}>
                    <Metas atendimento={this.props.atendimento}></Metas>
                  </Grid>
                )}
                {this.props.gerenciarAtendimento && this.props.isAtendimentoBP && (
                  <Grid item xs={6}>
                    <MetasGoverno atendimento={this.props.atendimento} getPrevisaoReceita={(e) => (this.props.previsaoReceita = e)} getMetaGoverno={(e) => (this.props.isMetaGoverno = e)} />
                  </Grid>
                )}
                {!this.props.isArquivado && !this.props.isPronto && !this.props.isNegociacao && (
                  <Producao
                    atendimento={this.props.atendimento}
                    isMetrologia={this.props.isMetrologia}
                    changeModal={this.changeModal}
                    modalProducao={this.modalProducao}
                    formatData={this.props.formatData}
                    formatDataWithSlash={this.formatDataWithSlash}
                    formatDataTimestamp={this.formatDataTimestamp}
                    hasValue={this.hasValue}
                    closeLoading={this.props.closeLoading}
                    showLoading={this.props.showLoading}
                    isAtendimentoLEAN={this.props.isAtendimentoLEAN}
                    isAtendimentoBrasilMaisLEAN={this.props.isAtendimentoBrasilMaisLEAN}
                  />
                )}
                {!this.props.isArquivado && !this.props.isPronto && !this.props.isNegociacao && (
                  <Recurso
                    atendimento={this.props.atendimento}
                    isMetrologia={this.props.isMetrologia}
                    changeModal={this.changeModal}
                    modalRecurso={this.modalRecurso}
                    formatData={this.props.formatData}
                    formatDataWithSlash={this.formatDataWithSlash}
                    showButton={true}
                    hasValue={this.hasValue}
                    closeLoading={this.props.closeLoading}
                    showLoading={this.props.showLoading}
                  />
                )}
                {!this.props.isPronto && !this.props.isArquivado && (
                  <Entrega
                    isPronto={this.props.isPronto}
                    isNegociacao={this.props.isNegociacao}
                    isAceito={this.props.isAceito}
                    isExecucao={this.props.isExecucao}
                    atendimento={this.props.atendimento}
                    modalEntrega={this.modalEntrega}
                    formatDataWithSlash={this.formatDataWithSlash}
                    changeModal={this.changeModal}
                    formatData={this.props.formatData}
                    openAtendimento={this.props.openAtendimento}
                    changeModalShowToast={this.changeModalShowToast}
                  />
                )}

                <Prospeccao
                  changeModal={this.changeModal}
                  modalProspeccao={this.modalProspeccao}
                  atendimento={this.props.atendimento}
                  usuarioConectado={this.props.usuario}
                  openAtendimento={this.props.openAtendimento}
                  changeModalShowToast={this.changeModalShowToast}
                />
                {(((this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL) && this.props.turma != null) ||
                  this.props.isAtendimentoIPEA ||
                  this.props.isAtendimentoIE ||
                  this.props.isAtendimentoLEAN) &&
                  !this.props.isPronto &&
                  !this.props.isNegociacao &&
                  !this.props.isArquivado && (
                    <Fase
                      atendimento={this.props.atendimento}
                      modalFase={this.modalFase}
                      isAceito={this.props.isAceito}
                      isExecucao={this.props.isExecucao}
                      isConcluido={this.props.isConcluido}
                      isCancelado={this.props.isCancelado}
                      isAtendimentoBrasilMaisLEAN={this.props.isAtendimentoBrasilMaisLEAN}
                      isAtendimentoDIGITAL={this.props.isAtendimentoDIGITAL}
                      turma={this.props.turma}
                      formatDataWithSlash={this.formatDataWithSlash}
                      changeModal={this.changeModal}
                      formatData={this.props.formatData}
                      openAtendimento={this.props.openAtendimento}
                      showToast={this.props.showToast}
                      closeLoading={this.props.closeLoading}
                      showLoading={this.props.showLoading}
                      showButton={true}
                    />
                  )}
                {(this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL) && (this.props.isUO || this.props.isDR) && this.props.isPronto && (
                  <ControleBrasilMais
                    atendimento={this.props.atendimento}
                    modalBrasilMais={this.modalBrasilMais}
                    isAceito={this.props.isAceito}
                    isExecucao={this.props.isExecucao}
                    isConcluido={this.props.isConcluido}
                    isCancelado={this.props.isCancelado}
                    isAtendimentoBrasilMaisLEAN={this.props.isAtendimentoBrasilMaisLEAN}
                    isAtendimentoDIGITAL={this.props.isAtendimentoDIGITAL}
                    turma={this.props.turma}
                    formatDataWithSlash={this.formatDataWithSlash}
                    changeModal={this.changeModal}
                    formatData={this.props.formatData}
                    openAtendimento={this.props.openAtendimento}
                    showToast={this.props.showToast}
                    closeLoading={this.props.closeLoading}
                    showLoading={this.props.showLoading}
                    showButton={true}
                  />
                )}
                {this.isBaseNacional && this.props.atendimento.solicitacaoStatus && (
                  <Grid item xs={12}>
                    <div className={"greyDiv"}>
                      <h2>Observação do Parecer DN</h2>
                      <p>{this.props.atendimento.observacao ? this.props.atendimento.observacao : "Este atendimento ainda não possui observação registrada."}</p>
                    </div>
                  </Grid>
                )}
                {!this.props.isNegociacao &&
                  !this.props.isPronto &&
                  !this.props.isArquivado &&
                  this.isBaseNacional &&
                  (((this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL) && this.props.turma != null) || this.props.indicadores != null) && (
                    <Indicadores
                      indicadores={this.props.indicadores}
                      atendimento={this.props.atendimento}
                      changeModal={this.changeModal}
                      isAtendimentoBP={this.props.isAtendimentoBP}
                      isAtendimentoIA={this.props.isAtendimentoIA}
                      isAtendimentoIPEA={this.props.isAtendimentoIPEA}
                      isAtendimentoLEAN28={this.props.isAtendimentoLEAN28}
                      isAtendimentoLEAN56={this.props.isAtendimentoLEAN56}
                      isAtendimentoMENTORIA={this.props.isAtendimentoMENTORIA}
                      isAtendimentoLEAN={this.props.isAtendimentoLEAN}
                      isAtendimentoIE={this.props.isAtendimentoIE}
                      isAtendimentoIA180={this.props.isAtendimentoIA180}
                      isAtendimentoDIGITAL={this.props.isAtendimentoDIGITAL}
                      isAtendimentoBrasilMaisLEAN={this.props.isAtendimentoBrasilMaisLEAN}
                      isAceito={this.props.isAceito}
                      isExecucao={this.props.isExecucao}
                      modalIndicadores={this.modalIndicadores}
                      openAtendimento={this.props.openAtendimento}
                      novosIndicadores={this.props.atendimento.indicadorAtendimentoReferencialList}
                      showToast={this.props.showToast}
                      showLoading={this.props.showLoading}
                      closeLoading={this.props.closeLoading}
                      gerenciarAtendimento={this.props.gerenciarAtendimento}
                      openIndicadoresScreen={this.openIndicadoresScreen}
                      changeModalShowToast={this.changeModalShowToast}
                      usuario={this.props.usuario}
                      turma={this.props.turma}
                      isDN={this.props.isDN}
                      isModuloNacional={this.props.isModuloNacional}
                      refreshAtendimento={this.props.refreshAtendimento}
                    />
                  )}
                {!this.props.isNegociacao &&
                  !this.props.isPronto &&
                  !this.props.isArquivado &&
                  this.isBaseNacional &&
                  (this.props.atendimento.anexoIndicadorProdutividadeList || this.props.atendimento.anexoAtendimentoReferencialList) && (
                    !this.state.loadingAnexos ? (
                      <Anexos
                        isModuloNacional={this.props.isModuloNacional}
                        title={"Anexos de indicadores"}
                        list={this.props.isModuloNacional ? this.state.anexosIndicadoresListNovo : this.props.atendimento.anexoIndicadorProdutividadeList}
                        gerenciarAtendimento={this.props.gerenciarAtendimento}
                        openAnexosScreen={this.openAnexosScreen}
                        showLoading={this.props.showLoading}
                        closeLoading={this.props.closeLoading}
                        showToast={this.props.showToast}
                        isDN={this.props.isDN}
                        atendimento={this.props.atendimento}
                        isAceito={this.props.isAceito}
                        isExecucao={this.props.isExecucao}
                      />
                    ) : (
                      <p>Carregando anexos...</p>
                    )
                  )}
                {this.props.atendimento.proposta && this.props.atendimento.proposta.anexoPropostaList && (
                  <Anexos 
                    title={"Anexos do atendimento"} 
                    list={this.props.atendimento.proposta.anexoPropostaList} 
                    proposta={true} 
                    idAtendimento={this.props.atendimento.id} 
                    showLoading={this.props.showLoading}
                    closeLoading={this.props.closeLoading}
                    showToast={this.props.showToast}
                  />
                )}
                {this.props.isAtendimentoBrasilMaisLEAN && !this.props.isPronto && !this.props.isNegociacao && !this.props.isArquivado && this.props.turma != null && (
                  <Evidencia turma={this.props.turma} atendimento={this.props.atendimento} showLoading={this.props.showLoading} closeLoading={this.props.closeLoading} />
                )}
                {this.props.isAtendimentoDIGITAL && !this.props.isPronto && !this.props.isNegociacao && !this.props.isArquivado && this.props.turma != null && (
                  <EvidenciaDigital turma={this.props.turma} atendimento={this.props.atendimento} showLoading={this.props.showLoading} closeLoading={this.props.closeLoading} />
                )}
                {this.props.gerenciarAtendimento && (
                  <Comentario
                    changeModal={this.changeModal}
                    atendimento={this.props.atendimento}
                    modalComentario={this.modalComentario}
                    openAtendimento={this.props.openAtendimento}
                    showLoading={this.props.showLoading}
                    closeLoading={this.props.closeLoading}
                    showToast={this.props.showToast}
                  />
                )}
                {this.props.gerenciarAtendimento && !this.props.isPronto && !this.props.isNegociacao && this.props.isInovacao && (
                  <PesquisaSatisfacao
                    atendimento={this.props.atendimento}
                    modalPesquisa={this.modalPesquisa}
                    changeModal={this.changeModal}
                    openAtendimento={this.props.openAtendimento}
                    showToast={this.props.showToast}
                    changeModalShowToast={this.changeModalShowToast}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              {btnVoltar}
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {this.indicadoresScreen &&
              (isMatchingProdutoNacional ? (
                <RegistroIndicadoresNovo
                  atendimento={this.props.atendimento}
                  showLoading={this.props.showLoading}
                  closeLoading={this.props.closeLoading}
                  showToast={this.props.showToast}
                  refreshAtendimento={this.props.refreshAtendimento}
                  openIndicadoresScreen={this.openIndicadoresScreen}
                  usuarioConectado={this.props.usuario}
                />
              ) : (
                <RegistroIndicadores
                  atendimento={this.props.atendimento}
                  showLoading={this.props.showLoading}
                  closeLoading={this.props.closeLoading}
                  showToast={this.props.showToast}
                  refreshAtendimento={this.props.refreshAtendimento}
                  openIndicadoresScreen={this.openIndicadoresScreen}
                  usuarioConectado={this.props.usuario}
                />
              ))}
            {this.anexosScreen && (
              <RegistroAnexos
                atendimento={this.props.atendimento}
                listAnexos={this.state.anexosIndicadoresListNovo}
                openAnexosScreen={this.openAnexosScreen}
                showLoading={this.props.showLoading}
                closeLoading={this.props.closeLoading}
                showToast={this.props.showToast}
                refreshAtendimento={this.props.refreshAtendimento}
                atualizarAnexos={this.atualizarAnexos}
                usuarioConectado={this.props.usuario}
              />
            )}
            {this.modalTrilha && this.props.isAtendimentoBrasilMaisLEAN && (
              <Trilha
                showToast={this.props.showToast}
                showLoading={this.props.showLoading}
                closeLoading={this.props.closeLoading}
                turma={this.props.turma}
                goBack={() => this.closeTrilha()}
                isUO={this.props.isUO}
                usuarioConectado={this.props.usuario}
              />
            )}
            {this.modalTrilha && this.props.isAtendimentoDIGITAL && (
              <TrilhaMentoriaDigital
                showToast={this.props.showToast}
                showLoading={this.props.showLoading}
                closeLoading={this.props.closeLoading}
                turma={this.props.turma}
                goBack={() => this.closeTrilha()}
                mentoresDados={this.props.mentoresDados}
                isUO={this.props.isUO}
                usuarioConectado={this.props.usuario}
              />
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}
