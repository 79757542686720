import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import React, { Component } from "react";
import Masks from "../../comm/masks";
import IndicadoresAlert from "../../components/AlertIndicadores/alertIndicadores";
import InputSelect from "../../components/inputselect/inputselect";
import SimpleModal from "../../components/modal/modal";
import { newBackend } from "./../../comm/API";
import FormField from "./../../components/formField/formField";
import GenericButton from "./../../components/genericButton/genericButton";
import Table from "./../../components/table/table";
import { IndicadoresEnum } from "./enum/indicadoresEnum";
import { indicadorTipoInput } from "./enum/indicadorTipoInput";
import { InputBMPEEEnum } from "./enum/inputBMPEE";
import { InputBMPManufaturaEnum } from "./enum/inputBMPManufatura";
import { InputBMPTDEnum } from "./enum/inputBMPTD";
import { InputBMPTDF2Enum } from "./enum/inputBMPTDF2";
import { InputSmartFactoryEnum } from "./enum/inputSmartFactory";
import { InputSmartFactoryPilotoEnum } from "./enum/inputSmartFactoryPiloto";
import { produtoNacionalBMPEnum } from "./enum/produtoNacionalBMP";
import { TipoInputEnum } from "./enum/tipoInput";
import { tipoInputSelectBMPTD } from "./enum/tipoInputSelectBMPTD";
import RegistroCNPJ from "./registroCNPJ";

export default class RegistroIndicadores extends Component {
  types = ["label", "label", "inputNumber", "inputNumber", "label", "icon"];

  registroTitlesInput = [
    { id: "0", canOrder: true, label: "Inputs Necessários" },
    { id: "1", canOrder: false, label: "Valor" },
    { id: "2", canOrder: false, label: "Unidade de Medida" },
  ];

  registroIndicadoresInputTitle = [
    { id: "0", canOrder: true, label: "Nome do indicador" },
    { id: "1", canOrder: false, label: "Resultado" },
    { id: "3", canOrder: false, label: "Fórmula" },
  ];

  recebeSmartFactory = [
    { id: 1, label: "Não", value: 0 },
    { id: 2, label: "Sim", value: 1 },
  ];

  registroIndicadoresInputTitleAvaliacao = this.registroIndicadoresInputTitle.concat([{ id: "4", canOrder: false, label: "Parecer DN" }]);
  registroIndicadoresInputTitleAvaliado = [{ id: "4", canOrder: false, label: "Parecer DN" }].concat(this.registroIndicadoresInputTitle);

  parecerDNOptions = {
    conforme: { id: 2, chave: "conforme", descricao: "Conforme" },
    naoConforme: { id: 3, chave: "naoconforme", descricao: "Não Conforme" },
  };

  indicadorReferencialLines = [];
  indicadorReferencialList = [];
  InputLines = [];
  InputList = [];
  atendimentoInputList = [];
  insertIndicadorAtendimentoReferencial = [];
  updateSolicitacaoStatus = [];
  medicoesIndicadores = {};
  inputsBMP = [];
  indicadoresBMP = [];

  constructor(props) {
    super(props);

    this.state = {
      atendimentoCP: JSON.parse(JSON.stringify(this.props.atendimento)),
      inputValues: {},
      isModalOpen: false,
      openModalCNPJ: false,
      programaBaseNacional: null,
      indicadores: [],
      inputsIndicadorSelect: [],
      cnpjValido: true,
      cnpjValues: "",
      numberOfCnpjs: undefined,
      numberOfCnpjsRegistered: undefined,
    };
    this.isDN = this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DN";
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  loadData = async () => {
    await this.getCnpjValues();
    await this.handleInputChange();
    await this.getIndicadoresRefresh();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getIndicadorReferencialLines = async () => {
    const idProdNacional = this.props.atendimento.produtoRegional.produtoNacional.id;
    const idAtendimento = this.props.atendimento.id;
    try {
      const res = await newBackend.get(`/programa-base-nacional/${idAtendimento}`);
      const programaBaseNacional = res.data;

      this.setState({ programaBaseNacional });

      this.inputsBMP = this.transformaObjetosInputs(programaBaseNacional.inputs);
      this.indicadoresBMP = this.transformaObjetosInputs(programaBaseNacional.indicadores);
      this.InputList = programaBaseNacional.inputs.sort((a, b) => a.id - b.id);
      this.indicadorReferencialList = programaBaseNacional.indicadores.sort((a, b) => a.id - b.id);

      switch (idProdNacional) {
        case produtoNacionalBMPEnum.manufaturaEnxuta:
        case produtoNacionalBMPEnum.smartFactoryPiloto:
          this.ajustarFormulaDoManufatura();
          break;
        case produtoNacionalBMPEnum.eficienciaEnergetica:
          this.sortEE(this.indicadorReferencialList);
          this.ajustarFormulaDoEE();
          break;
        case produtoNacionalBMPEnum.transformacaoFase2Piloto:
          this.ajustarFormulaDoTDFase2Piloto();
          break;
        default:
          break;
      }

      await newBackend
        .get(`/input-programa-atendimento/${idAtendimento}`)
        .then((resAtdInput) => {
          this.atendimentoInputList = resAtdInput.data.sort((i, j) => (i.id > j.id ? 1 : -1));
        })
        .catch((err) => {});
    } catch (error) {
      console.log(error);
      this.props.closeLoading();
      this.props.showToast("Erro ao Carregar os indicadores deste Atendimento. Favor entrar em contato com o suporte.", "error");
      this.backToResumo();
    }
    this.indicadorReferencialLines = this.indicadorReferencialList.map((indicador) => this.createLineRegistro(indicador));
  };

  ajustarFormulaDoManufatura = async () => {
    this.indicadorReferencialList.forEach((indicador) => {
      switch (indicador.nome) {
        case "Retorno do Programa":
          indicador.nome = "Retorno do Programa (payback)";
          indicador.formula = "((Carga horária STI + 4) x 190 + Investimento de Ações) / Ganho Financeiro Mensal";
          break;
        case "Produtividade Lean":
          indicador.formula = "[(Medição Final - Medição Inicial)/ Medição Inicial ] x 100";
          break;
        default:
          break;
      }
    });
  };

  ajustarFormulaDoTDFase2Piloto = async () => {
    this.indicadorReferencialList.forEach((indicador) => {
      switch (indicador.nome) {
        case "Retorno do Programa":
          indicador.nome = "Retorno do Programa (payback)";
          indicador.formula = "((120 x 250) + 1000 + Investimento de Ações) / Ganho Financeiro Mensal";
          break;
        case "Produtividade Lean":
          indicador.formula = "[(Medição Final - Medição Inicial)/ Medição Inicial ] x 100";
          break;
        default:
          break;
      }
    });
  };

  ajustarFormulaDoEE = async () => {
    this.indicadorReferencialList.forEach((indicador) => {
      switch (indicador.nome) {
        case "Redução de Consumo Energético":
          if (indicador.sufixo === "%") {
            indicador.formula = "(Medição inicial de Consumo - Medição final de Consumo) / Medição inicial de Consumo x 100";
            indicador.sufixo = "%";
          } else {
            indicador.formula = "Medição inicial de Consumo - Medição final de Consumo";
          }
          break;
        case "Redução de Custo com Energia":
          indicador.formula = "[(Medição inicial de Consumo - Medição final de Consumo) x valor KWh médio x 1000] + Economia com correção de fator de potência + Economia com readequação da demanda tarifária + Economia com reenquadramento tarifário";
          break;
        case "Retorno do Programa (EE)":
          indicador.nome = "Retorno do Programa (EE) (payback)";
          indicador.formula = "[((Carga horária STI + 4) x 190 + (Investimentos com ações da análise tarifária + Investimentos com usos finais) ) / Redução de Custo com Energia ] x 12";
          break;
        default:
          break;
      }
    });
  };

  sortEE = (indicadorReferencialList) => {
    const ordemIndicadoresEE = [5, 28, 6, 12, 13, 14, 29, 31];

    this.indicadorReferencialList = indicadorReferencialList.sort((a, b) => {
      const idA = a.id;
      const idB = b.id;

      const orderIndexA = ordemIndicadoresEE.indexOf(idA);
      const orderIndexB = ordemIndicadoresEE.indexOf(idB);

      return orderIndexA - orderIndexB;
    });
  };

  sortManufatura = (indicadorReferencialList) => {
    const ordemIndicadoresManufatura = [5, 28, 6, 12, 13, 14, 29, 31];

    this.indicadorReferencialList = indicadorReferencialList.sort((a, b) => {
      const idA = a.id;
      const idB = b.id;

      const orderIndexA = ordemIndicadoresManufatura.indexOf(idA);
      const orderIndexB = ordemIndicadoresManufatura.indexOf(idB);

      return orderIndexA - orderIndexB;
    });
  };

  getIndicadoresRefresh = async (index) => {
    if (this.isDN) {
      let atendimento = this.state.atendimentoCP;
      let indicadores = atendimento.indicadorAtendimentoReferencialList;
      if (this.indicadorReferencialLines.length === 0) {
        indicadores = indicadores.sort((curr, other) => (curr.indicadorReferencial.id > other.indicadorReferencial.id ? 1 : -1));
        await indicadores.forEach((indicador, i) => {
          this.indicadorReferencialLines.push(this.createLineAvaliacao(indicador, i));
        });
      } else {
        this.indicadorReferencialLines[index] = this.createLineAvaliacao(indicadores[index], index);
      }

      if (index >= 0) {
        this.indicadorReferencialLines[index] = this.createLineRegistro(this.indicadorReferencialList[index]);
      }
    } else if (index >= 0) {
      this.indicadorReferencialLines[index] = this.createLineRegistro(this.indicadorReferencialList[index]);
    } else {
      await this.getIndicadorReferencialLines();
    }
    this.setState(this.state);
    this.props.closeLoading();
  };

  handleInputChange = async (idInputToPrograma, novoValor, tipoInput) => {
    if (idInputToPrograma === undefined || novoValor === undefined) {
      return;
    }

    const existingItemIndex = this.atendimentoInputList.findIndex(
      (item) => item.id_programa_base_nacional_to_input_programa === idInputToPrograma && item.id_atendimento === this.props.atendimento.id
    );

    let parsedValue = novoValor;

    if (!isNaN(parseFloat(novoValor)) && isFinite(novoValor)) {
      parsedValue = parseFloat(novoValor);
    }

    if (tipoInput === TipoInputEnum.Cnpj) {
      parsedValue = this.removeDigitosEspeciaisCnpj(novoValor);
  
      if (parsedValue.length !== 14) {
        this.setState({cnpjValido: false});
      } else {
      try {
        const response = await newBackend.get(`/busca-cnpj/novo/${parsedValue}`);
        this.props.showToast(`CNPJ Válido! Razão Social: ${response.data.cliente.razaoSocial}`, 'success');
        this.setState({cnpjValido: true});
      } catch (error) {
          this.props.showToast('CNPJ inválido ou não encontrado na base de CNPJs. Favor solicitar o cadastro via Suporte.', 'error');
          this.setState({cnpjValido: false});
          return;
        }
      }
    }

    if (tipoInput === TipoInputEnum.ValorInteiro) {
      this.setState({ numberOfCnpjs: parsedValue }, () => {
      });
    }

    if (existingItemIndex !== -1) {
      this.atendimentoInputList = this.atendimentoInputList.map((item) => {
        if (item.id_programa_base_nacional_to_input_programa === idInputToPrograma && item.id_atendimento === this.props.atendimento.id) {
          return { ...item, valor: parsedValue?.toString() };
        }
        return item;
      });
    } else {
      const newItem = {
        valor: parsedValue?.toString(),
        id_programa_base_nacional_to_input_programa: idInputToPrograma,
        id_atendimento: this.props.atendimento.id,
      };
      this.atendimentoInputList.push(newItem);
    }

    this.indicadorReferencialLines = this.indicadorReferencialList.map((indicador) => this.createLineRegistro(indicador));
    this.findInputByProgramaAndAtendimento(idInputToPrograma);

    if (this._isMounted) {
      this.setState({}, () => {
        this.props.closeLoading();
      });
    }
  }

  getIndicadorAtendimentoRefModel = (indicador) => {
    return {
      indicadorReferencial: indicador,
      resultado: "",
      solicitacaoStatus: "",
    };
  };

  getInputTableForInputProgram = (id, placeholder, attr, index, isDisabled, inputValue, indicadores) => {
    const input = this.InputList.find((input) => input.id_programa_base_nacional_to_input_programa === id);
    const labelMappings = {
      2: "Inicial",
      3: "Final",
    };
    const labelText = labelMappings[input.id_tipo_input] || "";

    if (input) {
      let valor = inputValue;
      const atendimentoInput = this.atendimentoInputList.find((item) => item.id_programa_base_nacional_to_input_programa === id);
      if (atendimentoInput) {
        valor = atendimentoInput.valor?.toString();
      }

      if (this.isIndicadorListaCNPJ(indicadores, input)) {
        return (
          <div key={`input-${id}-${index}`}>
              <GenericButton color={"darkBlue"} label={"Adicionar CNPJs das empresas validadoras"} subClass={"basic-button"} click={() => { this.setState({ openModalCNPJ: true }); }} disabled={!this.state.numberOfCnpjs}/>
          </div>
        );
      } else if (this.isInputSelect(input.id_tipo_input)) {
        return (
          <div key={`input-${id}-${index}`}>
            <div className="input-indicadores">
              <InputSelect
                suggestions={tipoInputSelectBMPTD[input.id_tipo_input]}
                itemKey={"id"}
                itemLabel={"label"}
                style={{ width: "400px" }}
                id={"id"}
                getInput={(selectedOption) => this.handleInputChange(id, selectedOption.value, input.id_tipo_input)}
                initialSelectedItem={tipoInputSelectBMPTD[input.id_tipo_input]?.find((item) => item.value === valor)}
                error=""
              />
            </div>
          </div>
        );
      } else if (this.isInputCnpj(input.id_tipo_input)) {
        return (
          <div key={`input-${id}-${index}`}>
            {labelText && <label style={{ color: "#077CDC", fontSize: "13px" }}>{labelText}</label>}
            <FormField
              type={"text"}
              id={index}
              inputValue={Masks.maskByTipoPessoa(valor ? valor : "", 1)}
              changeValue={(e) => this.handleInputChange(id, e.target.value, input.id_tipo_input)}
              disabled={isDisabled}
            />
          </div>
        );
      } else if (this.isInputValorInteiro(input.id_tipo_input)) {
        return (
          <div key={`input-${id}-${index}`}>
            {labelText && <label style={{ color: "#077CDC", fontSize: "13px" }}>{labelText}</label>}
            <FormField
              type={"number"}
              changeValue={(e) => this.handleInputChange(id, e.target.value, input.id_tipo_input)}
              id={index}
              subtype={"inteiro"}
              inputValue={valor ?? 0}
              title={`${labelText} ${placeholder}`}
              placeholder={`${labelText} ${placeholder}`}
              name={attr}
              min="1"
              max="40"
              maxLength="20"
              disabled={isDisabled}
            />
          </div>
        )
      } else {
        return (
          <div key={`input-${id}-${index}`}>
            {labelText && <label style={{ color: "#077CDC", fontSize: "13px" }}>{labelText}</label>}
            <FormField
              type={"number"}
              changeValue={(e) => this.handleInputChange(id, e.target.value, input.id_tipo_input)}
              id={index}
              subtype={"seiscasas"}
              inputValue={valor}
              title={`${labelText} ${placeholder}`}
              placeholder={`${labelText} ${placeholder}`}
              name={attr}
              min="1"
              maxLength="20"
              disabled={isDisabled}
            />
          </div>
        );
      }
    } else {
      console.error("Invalid input object:", input);
      return null;
    }
  };

  getIconTable = (formula) => {
    let prefixFormula = "-";
    let codigoDN = String(this.props.atendimento.produtoRegional.produtoNacional.codigoDN);

    if (String(codigoDN) === "412158") {
      prefixFormula = "";
    }

    const icon = <img src={"./../../assets/icons/formula-indicador.svg"} alt="" />;
    return (
      <div className={"tooltip-indicadores"}>
        <div>
          <span className={"formula"}>
            <Grid container>
              <Grid item container>
                <Grid item> {icon} </Grid>
                <Grid item>
                  <label className={"icon-label"}> Fórmula do resultado </label> <br />
                </Grid>
              </Grid>
              <Grid item>
                {" "}
                <label>
                  {" "}
                  {prefixFormula} {formula}{" "}
                </label>{" "}
              </Grid>
            </Grid>
          </span>
        </div>
        {icon}
      </div>
    );
  };

  getIconInfoInput = (descricao) => {
    const icon = <img src={"./../../assets/icons/formula-indicador.svg"} alt="" />;
    return (
      <div className={"tooltip-indicadores"}>
        <div>
          <span className={"formula"}>
            <Grid container>
              <Grid item container>
                <Grid item> {icon} </Grid>
                <Grid item>
                  <label className={"icon-label"}> Descrição do Indicador </label> <br />
                </Grid>
              </Grid>
              <Grid item>
                {" "}
                <label> {descricao} </label>{" "}
              </Grid>
            </Grid>
          </span>
        </div>
        {icon}
      </div>
    );
  };

  checkParecerDN = (indicador, index) => {
    let solicitacaoStatus = indicador.solicitacaoStatus ? indicador.solicitacaoStatus.descricao : "";
    const setIcon = (icon) => <img src={`./../../assets/icons/${icon}.svg`} alt="" />;
    return (
      <Grid item xs={12}>
        <Grid container style={{ marginBottom: "3%" }} direction={"row"}>
          <Grid item className={"radioDisabled"}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="position" name={`radio-group-${index}`} value={solicitacaoStatus} onChange={this.changeInput} row>
                <Grid container direction={"row"}>
                  <Grid item xs={5} className={"tooltip-solicitacao"}>
                    <span className={"tooltip-info avaliar"}> Aprovar </span>
                    <FormControlLabel
                      value={"Conforme"}
                      control={
                        <Radio
                          color="primary"
                          disabled={false}
                          checkedIcon={setIcon("conforme-checked")}
                          icon={setIcon(solicitacaoStatus === "Não Conforme" ? "conforme-gray" : "conforme-unchecked")}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={5} className={"tooltip-solicitacao"}>
                    <span className={"tooltip-info avaliar"}> Reprovar </span>
                    <FormControlLabel
                      value={"Não Conforme"}
                      control={
                        <Radio
                          color="primary"
                          disabled={false}
                          checkedIcon={setIcon("nao-conforme-checked")}
                          icon={setIcon(solicitacaoStatus === "Conforme" ? "nao-conforme-gray" : "nao-conforme-unchecked")}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  displayResult = (ref, result) => {
    const formatNumber = (value) =>
      value.toLocaleString("pt-BR", {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      });

    const isIndicadorCnpj = this.isIndicadorCnpj(ref);

    if (isNaN(result)) {
      return "-";
    } else if (typeof result === "string") {
      return isIndicadorCnpj ? Masks.maskByTipoPessoa(result || "", 1) : result;
    } else {
      return formatNumber(result);
    }
  };

  Resultado = (ref) => {
    let result = this.calculaFormula(ref, NaN);
    const [indicador] = this.state.inputsIndicadorSelect.filter((indicador) => indicador.id === ref.id);

    ref.sufixo = ref.sufixo !== "(0-5)" ? ref.sufixo : "";

    const displayResult = () => {
      if (result === 'Infinity') {
        return 'Erro no cálculo: Divisão por zero'; 
      } else if (ref.id === IndicadoresEnum.CNPJDaEmpresaValidadora) {
        return this.state.numberOfCnpjsRegistered ? this.state.numberOfCnpjsRegistered : '-';
      } else {
        return `${this.displayResult(ref, result)} ${ref.sufixo}`; 
      }
    };
  
    return (
      <label>
        <b>
          {indicador
            ? `${tipoInputSelectBMPTD[indicador.id_tipo_input].find((input) => input.value === indicador.valor)?.label} ${ref.sufixo}`
            : displayResult()}
        </b>
      </label>
    );
  };
  

  changeInput = (e) => {
    const index = parseInt(e.target.name.split("-")[2]);
    let atendimento = this.state.atendimentoCP;
    let indicador = atendimento.indicadorAtendimentoReferencialList[index];
    indicador.solicitacaoStatus = e.target.value === "Conforme" ? this.parecerDNOptions.conforme : this.parecerDNOptions.naoConforme;
    atendimento.indicadorAtendimentoReferencialList[index] = indicador;
    this.updateSolicitacaoStatus.push({ id: indicador.id, idsolicitacaostatus: indicador.solicitacaoStatus.id });

    this.setState({ atendimentoCP: atendimento });
    this.getIndicadoresRefresh(index);
  };

  createLine = (indicador) => {
    let referencial = indicador.indicadorReferencial;
    let parecerDNOptions = { naoconforme: "reprovado", conforme: "aprovado" };
    let iconParecer = indicador.solicitacaoStatus ? parecerDNOptions[indicador.solicitacaoStatus.chave] : "nao-avaliado";
    let montar;

    if (this.isDN) {
      montar = [<b>{referencial.nome}</b>, <b>{`${indicador.resultado} ${referencial.sufixo}`}</b>, "", this.getIconTable(referencial.formula)];
    } else {
      montar = [<b>{referencial.nome}</b>, this.Resultado(referencial), "", this.getIconTable(referencial.formula)];
    }

    let avaliado = (
      <Grid container className={"indicadores-avaliados"}>
        <Grid item>
          {" "}
          <img src={`./../../assets/icons/${iconParecer}.svg`} alt="" />{" "}
        </Grid>
        <Grid item>
          {" "}
          <label> {iconParecer === "nao-avaliado" ? "não avaliado" : iconParecer} </label>{" "}
        </Grid>
      </Grid>
    );
    montar = this.isDN ? montar : montar.concat(avaliado);

    return montar;
  };

  createLineInputProgram = (inputs, indicadores) => {
    const groupedInputs = {};

    inputs.forEach((input) => {
      if (!groupedInputs[input.nome]) {
        groupedInputs[input.nome] = [];
      }
      groupedInputs[input.nome].push(input);
    });

    const lines = [];

    for (const nomeInput in groupedInputs) {
      const inputs = groupedInputs[nomeInput].sort((a, b) => a.id_tipo_input - b.id_tipo_input);
      let inputRender;

      if (inputs.length > 1) {
        inputRender = (
          <Grid key={`input-${inputs[0].id_programa_base_nacional_to_input_programa}`} container spacing={2}>
            {inputs.map((input, index) => (
              <Grid item xs={6} key={`input-${input.id_programa_base_nacional_to_input_programa}-${index}`}>
                {this.getInputTableForInputProgram(input.id_programa_base_nacional_to_input_programa, input.placeholder, "valor", index, false, input.valor, indicadores)}
              </Grid>
            ))}
          </Grid>
        );
      } else {
        inputRender = (
          <Grid key={`input-${inputs[0].id_programa_base_nacional_to_input_programa}`} item xs={12}>
            {this.getInputTableForInputProgram(inputs[0].id_programa_base_nacional_to_input_programa, inputs[0].placeholder, "valor", 0, false, inputs[0].valor, indicadores)}
          </Grid>
        );
      }

      const montar = [
        <b key={`nome-${inputs[0].id_programa_base_nacional_to_input_programa}`}>{nomeInput}</b>,
        inputRender,
        <b key={`sufixo-${inputs[0].id_programa_base_nacional_to_input_programa}`}>{inputs[0].sufixo}</b>,
      ];

      lines.push(montar);
    }

    return lines;
  };

  createLineAvaliacao = (indicador, index) => {
    let line = this.createLine(indicador);
    line.push(this.checkParecerDN(indicador, index));
    return line;
  };

  createLineRegistro = (indicador) => {
    let sort = (indicadores) => indicadores.sort((i, j) => (i.id > j.id ? 1 : -1));
    let atendimento = this.state.atendimentoCP;
    let indicadores = atendimento.indicadorAtendimentoReferencialList;
    atendimento.indicadorAtendimentoReferencialList = sort(indicadores);
    let indicadorAtdRef = atendimento.indicadorAtendimentoReferencialList.find((obj) => obj.indicadorReferencial.id === indicador.id);
    if (indicadorAtdRef) {
      indicadorAtdRef.indicadorReferencial.formula = indicador.formula;
    } else {
      indicadorAtdRef = this.getIndicadorAtendimentoRefModel(indicador);
      indicadores.push(indicadorAtdRef);
      atendimento.indicadorAtendimentoReferencialList = sort(indicadores);
      this.setState({ atendimentoCP: atendimento });
    }

    return this.createLine(indicadorAtdRef);
  };

  mapearIndicadores = () => {
    const atendimentoIndicadorReferencial = [];
    this.insertIndicadorAtendimentoReferencial.forEach((item) => {
      const indicador = this.indicadorReferencialList.find((indicador) => indicador.id === item.idindicadorreferencial);
      if (indicador) {
        atendimentoIndicadorReferencial.push({
          id: item.id,
          indicadorReferencial: indicador,
          resultado: item.resultado,
        });
      }
    });

    return atendimentoIndicadorReferencial;
  };

  salvarRegistros = async () => {
    let atendimento = JSON.parse(JSON.stringify(this.state.atendimentoCP));
    const { programaBaseNacional } = this.state;
    try {
      this.props.showLoading();

      const cnpjValues = this.state.cnpjValues.split(" ; ").filter(cnpj => cnpj.trim() !== "");
      if (cnpjValues.length > 0) {
        this.handleCnpjValues(cnpjValues);
      }

      if (!this.isDN) {
        atendimento.indicadorAtendimentoReferencialList = this.mapearIndicadores();
        const inputSelectValue = this.getValorAtendimentoInput(InputBMPEEEnum.SmartFactory);
        const participaraDoSmartFactory = programaBaseNacional.inputs.some((objeto) => objeto.name === "Está apta a receber o Smart Factory");
        if (participaraDoSmartFactory && inputSelectValue === undefined) {
          this.props.showToast("Para Concluir o Registro preencha todos os Campos.", "error");
          this.props.closeLoading();
          return;
        }
      }
      const allHaveResultado = this.insertIndicadorAtendimentoReferencial.every(item => item.resultado !== null && item.resultado !== undefined && item.resultado !== Infinity && item.resultado !== "NaN" && item.resultado !== "Infinity");

      const cnpjInvalido = this.state.numberOfCnpjs < this.state.numberOfCnpjsRegistered;

      if (!allHaveResultado || !this.state.cnpjValido) {
        this.props.showToast("Erro ao registrar os indicadores. Favor preencher todos os campos corretamente.", "error");
        this.props.closeLoading();
        return;
      }
      if(cnpjInvalido) {
        this.props.showToast("Erro ao registrar os indicadores. O Número de Empresas Validadoras é menor que a quantidade de CNPJ da Empresa Validadora", "error");
        this.props.closeLoading();
        return;
      }
      if (this.isDN) {
        await newBackend.patch(`/indicador-atendimento-referencial/solicitacao-status`, this.updateSolicitacaoStatus);
      } else {
        await newBackend.post(`/indicador-atendimento-referencial/brasil-mais-produtivo`, { indicadores: this.insertIndicadorAtendimentoReferencial, inputs: this.atendimentoInputList });
      }

      this.insertIndicadorAtendimentoReferencial.forEach(indicador => {
        let matchingItem = atendimento.indicadorAtendimentoReferencialList.find(item => item.id === indicador.id);
        
        if (matchingItem) {
          matchingItem.medicaofinal = (indicador.medicaofinal !== undefined && !isNaN(indicador.medicaofinal)) ? indicador.medicaofinal : null;
          matchingItem.medicaoinicial = (indicador.medicaoinicial !== undefined && !isNaN(indicador.medicaoinicial)) ? indicador.medicaoinicial : null;
        }
      });
      
      atendimento.registroBMP = true;
      this.setState({ atendimentoCP: atendimento });
      this.props.refreshAtendimento(atendimento);
      this.props.closeLoading();
      this.props.showToast(!this.isDN ? "Indicadores registrados com sucesso!" : "Indicadores avaliados com sucesso!", "success");
      this.backToResumo();
    } catch (error) {
      this.props.closeLoading();
      this.props.showToast("Erro ao registrar os indicadores. Favor entrar em contato com o suporte.", "error");
      this.backToResumo();
    }
  };

  backToResumo = () => {
    window.scrollTo(0, 0);
    this.props.openIndicadoresScreen(false);
  };

  findInputByProgramaAndAtendimento(idInputPrograma) {
    const atendimentoInput = this.atendimentoInputList.find((item) => item.id_programa_base_nacional_to_input_programa === idInputPrograma && item.id_atendimento === this.props.atendimento.id);

    return atendimentoInput || { valor: 0 };
  }

  getValorAtendimentoInput({ nome, id_tipo_input }, getProps = false) {

    const inputs = this.InputList.find((item) => item.nome === nome && item.id_tipo_input === id_tipo_input);

    if (inputs) {
      const atendimentoInput = this.atendimentoInputList.find(
        (item) => item.id_programa_base_nacional_to_input_programa === inputs.id_programa_base_nacional_to_input_programa && item.id_atendimento === this.props.atendimento.id
      );

      if (atendimentoInput) {
        if (getProps) {
          return {
            ...atendimentoInput,
            valor: id_tipo_input !== TipoInputEnum.Cnpj ? parseFloat(atendimentoInput.valor) : atendimentoInput.valor,
            id_tipo_input,
          };
        } else {
          return id_tipo_input !== TipoInputEnum.Cnpj ? parseFloat(atendimentoInput.valor) : atendimentoInput.valor;
        }
      }
    }

    return undefined;
  }

  calcularVariacaoPercentualMedicoes(medicaoFinal, medicaoInicial) {
    const variacaoPercentual = (100 * (medicaoFinal - medicaoInicial)) / medicaoInicial;
    return variacaoPercentual;
  }

  salvarMedicoes(medicaoFinal, medicaoInicial, idIndicador) {
    this.medicoesIndicadores = {
      ...this.medicoesIndicadores,
      [this.props.atendimento.id]: {
        [idIndicador]: {
          medicaoFinal,
          medicaoInicial,
        },
      },
    };
  }

  isInputSelect = (idInput) => {
    return idInput in tipoInputSelectBMPTD;
  };

  isInputCnpj = (idInput) => {
    return idInput === TipoInputEnum.Cnpj;
  };

  isInputValorInteiro = (idInput) => {
    return idInput === TipoInputEnum.ValorInteiro;
  }

  isIndicadorListaCNPJ = (indicadores, input) => {
    const indicador = indicadores.find((indicador) => indicador.nome === "CNPJs das Empresas Validadoras");
    return indicador && input.nome === "CNPJ da Empresa Validadora";
  };

  isIndicadorSelect = (indicadorRef) => {
    const tipoInput = indicadorTipoInput.find((item) => item.id === indicadorRef.id)?.tipo;
    return tipoInput && tipoInputSelectBMPTD[tipoInput];
  };

  isIndicadorCnpj = (indicadorRef) => {
    const tipoInput = indicadorTipoInput.find((item) => item.id === indicadorRef.id)?.tipo;
    return tipoInput === TipoInputEnum.Cnpj;
  };

  calculaFormula(ref, result) {
    const idProdutoNacional = this.props.atendimento.produtoRegional.produtoNacional.id;
    const diffMediInicialFinalEE = Math.abs(this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoInicialConsumo) - this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoFinalConsumo));
    const medicaoInicalEE = this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoInicialConsumo);
    const medicaoFinalEE = this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoFinalConsumo);
    const reducaoCustoEnergetico = Math.abs(
      diffMediInicialFinalEE * this.getValorAtendimentoInput(InputBMPEEEnum.ValorKWhMedio) * 1000 +
        (this.getValorAtendimentoInput(InputBMPEEEnum.EconomiaCorrecaoFatorPotencia) +
          this.getValorAtendimentoInput(InputBMPEEEnum.EconomiaReadequacaoDemandaContratada) +
          this.getValorAtendimentoInput(InputBMPEEEnum.EconomiaReequadramentoTarifario))
    );
    const porteEmpresa = this.props.atendimento.numeroDeProducaoEstimada;

    const operacoes = {
      [produtoNacionalBMPEnum.eficienciaEnergetica]: {
        [IndicadoresEnum.ReducaoDeConsumoEnergeticoPercentual]: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 5);
          return Math.abs((diffMediInicialFinalEE / this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoInicialConsumo)) * 100);
        },
        [IndicadoresEnum.ReducaoDeConsumoEnergetico]: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 28);
          return diffMediInicialFinalEE;
        },
        [IndicadoresEnum.ReducaoDeCustoComEnergia]: () => reducaoCustoEnergetico,
        [IndicadoresEnum.EmissaoDeCO2Evitada]: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 12);
          return Math.abs(diffMediInicialFinalEE * 0.0426);
        },
        [IndicadoresEnum.UsinaEquivalente]: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 13);
          return Math.abs(diffMediInicialFinalEE / 4169.76);
        },
        [IndicadoresEnum.CasaEquivalente]: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 14);
          return Math.abs(diffMediInicialFinalEE / 1.98);
        },
        [IndicadoresEnum.RetornoDoProgramaEE]: () =>
          Math.abs(
            (((porteEmpresa + 4) * 190 + (this.getValorAtendimentoInput(InputBMPEEEnum.InvestimentoAcaoAnaliseTarifaria) + this.getValorAtendimentoInput(InputBMPEEEnum.InvestimentoUsosFinais))) /
              reducaoCustoEnergetico) *
              12
          ),
          [IndicadoresEnum.EmpresaAptaACategoriaSmartFactory]: () => this.getValorAtendimentoInput(InputBMPEEEnum.SmartFactory, true),
      },
      [produtoNacionalBMPEnum.manufaturaEnxuta]: {
        [IndicadoresEnum.RetornoDoPrograma]: () =>
          Math.abs(((porteEmpresa + 4) * 190 + this.getValorAtendimentoInput(InputBMPManufaturaEnum.InvestimentoDeAcoes)) / this.getValorAtendimentoInput(InputBMPManufaturaEnum.GanhoFinanceiro)),
        [IndicadoresEnum.ProdutividadeLean]: () => {
          const produtividadeInicial = Math.abs(
            (this.getValorAtendimentoInput(InputBMPManufaturaEnum.QuantidadeProduzidaInicial) / this.getValorAtendimentoInput(InputBMPManufaturaEnum.QuantidadeHorasTurnoInicial)) /
              this.getValorAtendimentoInput(InputBMPManufaturaEnum.NumeroOperadoresTurnoInicial)
          );
          const produtividadeFinal = Math.abs(
            (this.getValorAtendimentoInput(InputBMPManufaturaEnum.QuantidadeProduzidaFinal) / this.getValorAtendimentoInput(InputBMPManufaturaEnum.QuantidadeHorasTurnoFinal)) /
              this.getValorAtendimentoInput(InputBMPManufaturaEnum.NumeroOperadoresTurnoFinal)
          );
          this.salvarMedicoes(produtividadeFinal, produtividadeInicial, 1);
          return (100 * (produtividadeFinal - produtividadeInicial)) / produtividadeInicial;
        },
        [IndicadoresEnum.GanhoFinanceiroMensal]: () => Math.abs(this.getValorAtendimentoInput(InputBMPManufaturaEnum.GanhoFinanceiro)),
        [IndicadoresEnum.EmpresaAptaACategoriaSmartFactory]: () => this.getValorAtendimentoInput(InputBMPEEEnum.SmartFactory, true),
      },
      [produtoNacionalBMPEnum.smartFactoryPiloto]: {
        [IndicadoresEnum.ProdutividadeLean]: () => {
          const produtividadeInicial = Math.abs(
            (this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.QuantidadeProduzidaInicial) / this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.QuantidadeHorasTurnoInicial)) *
              this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.NumeroOperadoresTurnoInicial)
          );
          const produtividadeFinal = Math.abs(
            (this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.QuantidadeProduzidaFinal) / this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.QuantidadeHorasTurnoFinal)) *
              this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.NumeroOperadoresTurnoFinal)
          );
          const result = (100 * (produtividadeFinal - produtividadeInicial)) / produtividadeInicial;
          this.salvarMedicoes(produtividadeFinal, produtividadeInicial, 1);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.QualidadeOEE]: () => {
          const result = this.calcularVariacaoPercentualMedicoes(
            this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.QualidadeOEEFinal),
            this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.QualidadeOEEInicial)
          );
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.QualidadeOEEFinal), this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.QualidadeOEEInicial), 15);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.OEE]: () => {
          const result = this.calcularVariacaoPercentualMedicoes(this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.OEEFinal), this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.OEEInicial));
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.OEEFinal), this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.OEEInicial), 8);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.ReducaoDeMovimentacaoLean]: () => {
          const result = this.calcularVariacaoPercentualMedicoes(
            this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.ReducaoLeanFinal),
            this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.ReducaoLeanInicial)
          );
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.ReducaoLeanFinal), this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.ReducaoLeanInicial), 3);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.DisponibilidadeOEE]: () => {
          const result = this.calcularVariacaoPercentualMedicoes(
            this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.DisponibilidadeOEEFinal),
            this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.DisponibilidadeOEEInicial)
          );
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.DisponibilidadeOEEFinal), this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.DisponibilidadeOEEInicial), 9);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.PerformanceOEE]: () => {
          const result = this.calcularVariacaoPercentualMedicoes(
            this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.DisponibilidadeOEEFinal),
            this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.DisponibilidadeOEEInicial)
          );
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.DisponibilidadeOEEFinal), this.getValorAtendimentoInput(InputSmartFactoryPilotoEnum.DisponibilidadeOEEInicial), 10);
          return isNaN(result) ? 0 : result;
        },
      },
      [produtoNacionalBMPEnum.transformacaoFase1Piloto]: {
        [IndicadoresEnum.ProcessosNivelDeIntegracaoVertical]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelIntegracaoVertical, true),
        [IndicadoresEnum.ProcessosNivelDeIntegracaoHorizontal]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelIntegracaoHorizontal, true),
        [IndicadoresEnum.ProcessosNivelDeIntegracaoDoCicloDeVidaDoProduto]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelIntegracaoCicloVidaProduto, true),
        [IndicadoresEnum.TecnologiaNivelDeAutomacaoNoChaoDeFabrica]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelAutomacaoChaoFabrica, true),
        [IndicadoresEnum.TecnologiaNivelDeAutomacaoNaEmpresa]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelAutomacaoEmpresa, true),
        [IndicadoresEnum.TecnologiaNivelDeAutomacaoNasInstalacoes]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelAutomacaoInstalacoes, true),
        [IndicadoresEnum.TecnologiaNivelDeConectividadeNoChaoDeFabrica]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelConectividadeChaoFabrica, true),
        [IndicadoresEnum.TecnologiaNivelDeConectividadeNaEmpresa]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelConectividadeEmpresa, true),
        [IndicadoresEnum.TecnologiaNivelDeConectividadeNasInstalacoes]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelConectividadeInstalacoes, true),
        [IndicadoresEnum.TecnologiaNivelDeInteligenciaNoChaoDeFabrica]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelInteligenciaChaoFabrica, true),
        [IndicadoresEnum.TecnologiaNivelDeInteligenciaNaEmpresa]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelInteligenciaEmpresa, true),
        [IndicadoresEnum.TecnologiaNivelDeInteligenciaNasInstalacoes]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelInteligenciaInstalacoes, true),
        [IndicadoresEnum.OrganizacaoNivelDeDesenvolvimentoECapacitacaoDaMaoDeObra]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelDesenvolvimentoCapacitacaoMaoObra, true),
        [IndicadoresEnum.OrganizacaoNivelDeLideranca]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelLideranca, true),
        [IndicadoresEnum.OrganizacaoNivelDeColaboracaoIntraEEntreEmpresas]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelColaboracaoIntraEntreEmpresas, true),
        [IndicadoresEnum.OrganizacaoNivelDeEstrategiaEGovernanca]: () => this.getValorAtendimentoInput(InputBMPTDEnum.NivelEstrategiaGovernanca, true),
        [IndicadoresEnum.DimensaoPriorizadaParaInvestimento]: () => this.getValorAtendimentoInput(InputBMPTDEnum.AreaPriorizadaInvestimento, true),
        [IndicadoresEnum.FaixaDeInvestimento]: () => this.getValorAtendimentoInput(InputBMPTDEnum.FaixaInvestimento, true),
        [IndicadoresEnum.IncluiHardware]: () => this.getValorAtendimentoInput(InputBMPTDEnum.IncluiHardware, true),
        [IndicadoresEnum.IncluiSoftware]: () => this.getValorAtendimentoInput(InputBMPTDEnum.IncluiSoftware, true),
        [IndicadoresEnum.IncluiServico]: () => this.getValorAtendimentoInput(InputBMPTDEnum.IncluiServico, true),
        [IndicadoresEnum.TecnologiaPrincipal]: () => this.getValorAtendimentoInput(InputBMPTDEnum.TecnologiaPrincipal, true),
        [IndicadoresEnum.OpcaoDaEmpresa]: () => this.getValorAtendimentoInput(InputBMPTDEnum.OpcaoInvestimentoEmpresa, true),
        [IndicadoresEnum.OportunidadeDePDIIdentificada]: () => this.getValorAtendimentoInput(InputBMPTDEnum.OportunidadePDIIndentificada, true),
        [IndicadoresEnum.CNPJDoFornecedorPrincipal]: () => this.getValorAtendimentoInput(InputBMPTDEnum.CnpjFornecedorPrincipal, true),
      },
      [produtoNacionalBMPEnum.transformacaoFase2Piloto]: {
        [IndicadoresEnum.ProdutividadeLean]: () => {
          const produtividadeInicial = Math.abs(
            (this.getValorAtendimentoInput(InputBMPTDF2Enum.QuantidadeProduzidaInicial) / this.getValorAtendimentoInput(InputBMPTDF2Enum.QuantidadeHorasTurnoInicial)) *
              this.getValorAtendimentoInput(InputBMPTDF2Enum.NumeroOperadoresTurnoInicial)
          );
          const produtividadeFinal = Math.abs(
            (this.getValorAtendimentoInput(InputBMPTDF2Enum.QuantidadeProduzidaFinal) / this.getValorAtendimentoInput(InputBMPTDF2Enum.QuantidadeHorasTurnoFinal)) *
              this.getValorAtendimentoInput(InputBMPTDF2Enum.NumeroOperadoresTurnoFinal)
          );
          const result = (100 * (produtividadeFinal - produtividadeInicial)) / produtividadeInicial;
          this.salvarMedicoes(produtividadeFinal, produtividadeInicial, 1);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.RetornoDoPrograma]: () => Math.abs(((120 * 250) + 1000 + this.getValorAtendimentoInput(InputBMPTDF2Enum.InvestimentoDeAcoes)) / this.getValorAtendimentoInput(InputBMPTDF2Enum.GanhoFinanceiro)),
        [IndicadoresEnum.OEE]: () => {
          const result = this.calcularVariacaoPercentualMedicoes(this.getValorAtendimentoInput(InputBMPTDF2Enum.OEEFinal), this.getValorAtendimentoInput(InputBMPTDF2Enum.OEEInicial));
          this.salvarMedicoes(this.getValorAtendimentoInput(InputBMPTDF2Enum.OEEFinal), this.getValorAtendimentoInput(InputBMPTDF2Enum.OEEInicial), 8);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.DisponibilidadeOEE]: () => {
          const result = this.calcularVariacaoPercentualMedicoes(
            this.getValorAtendimentoInput(InputBMPTDF2Enum.DisponibilidadeOEEFinal),
            this.getValorAtendimentoInput(InputBMPTDF2Enum.DisponibilidadeOEEInicial)
          );
          this.salvarMedicoes(this.getValorAtendimentoInput(InputBMPTDF2Enum.DisponibilidadeOEEFinal), this.getValorAtendimentoInput(InputBMPTDF2Enum.DisponibilidadeOEEInicial), 9);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.PerformanceOEE]: () => {
          const result = this.calcularVariacaoPercentualMedicoes(
            this.getValorAtendimentoInput(InputBMPTDF2Enum.PerformanceOEEFinal),
            this.getValorAtendimentoInput(InputBMPTDF2Enum.PerformanceOEEInicial)
          );
          this.salvarMedicoes(this.getValorAtendimentoInput(InputBMPTDF2Enum.PerformanceOEEFinal), this.getValorAtendimentoInput(InputBMPTDF2Enum.PerformanceOEEInicial), 10);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.QualidadeOEE]: () => {
          const result = this.calcularVariacaoPercentualMedicoes(
            this.getValorAtendimentoInput(InputBMPTDF2Enum.QualidadeOEEFinal),
            this.getValorAtendimentoInput(InputBMPTDF2Enum.QualidadeOEEInicial)
          );
          this.salvarMedicoes(this.getValorAtendimentoInput(InputBMPTDF2Enum.QualidadeOEEFinal), this.getValorAtendimentoInput(InputBMPTDF2Enum.QualidadeOEEInicial), 15);
          return isNaN(result) ? 0 : result;
        },
        [IndicadoresEnum.CNPJDoFornecedorPrincipal]: () => this.getValorAtendimentoInput(InputBMPTDF2Enum.CnpjFornecedorPrincipal, true),
      },
      [produtoNacionalBMPEnum.smartFactory]: {
        [this.indicadoresBMP['Ganho Médio de Produtividade']?.id]: () => {
          const result = this.getValorAtendimentoInput(this.inputsBMP['Ganho Médio de Produtividade']);
          return result;
        },
        [this.indicadoresBMP['Ganho Médio de OEE']?.id]: () => {
          const result = this.getValorAtendimentoInput(this.inputsBMP['Ganho Médio de OEE']);
          return result;
        },
        [this.indicadoresBMP['Número de Empresas Validadoras aprovado']?.id]: () => {
          const result = this.getValorAtendimentoInput(this.inputsBMP['Número de Empresas Validadoras aprovado']);
          this.setState({ numberOfCnpjs: result });
          return result;
        },
        [this.indicadoresBMP['CNPJs das Empresas Validadoras']?.id]: () => {
          const result = this.state.cnpjValues;
          return result;
        },
      }
    };


    const operacao = operacoes[idProdutoNacional] && operacoes[idProdutoNacional][ref.id];

    if (operacao) {
      result = operacao();
      
      if(result === 0) {
        const existingItemIndex = this.insertIndicadorAtendimentoReferencial.findIndex((item) => item.idindicadorreferencial === ref.id);
        if (existingItemIndex !== -1) {
          this.insertIndicadorAtendimentoReferencial.splice(existingItemIndex, 1);
        }
      }

      if (result) {
        if (typeof result === "number" && !this.isIndicadorSelect(ref) && !isNaN(result)) {
          result = Number.isInteger(result) ? result : parseFloat(result.toFixed(6));
        }

        if (result.valor !== undefined) {
          result.valor = result.valor?.toString();
        }

        if (this.isInputSelect(result?.id_tipo_input)) {
          const inputsIndicadorSelect = this.state.inputsIndicadorSelect;
          const index = inputsIndicadorSelect.findIndex((obj) => obj.id === ref.id);

          if (index !== -1) {
            inputsIndicadorSelect[index] = { id: ref.id, valor: result.valor, id_tipo_input: result.id_tipo_input };
          } else {
            inputsIndicadorSelect.push({ id: ref.id, valor: result.valor, id_tipo_input: result.id_tipo_input });
          }

          this.setState({ inputsIndicadorSelect });
          this.inserirOuAtualizarResultado(ref.id, result.valor);
          return result.valor;
        } else {
          if (result.id_tipo_input === TipoInputEnum.Cnpj) {
            this.inserirOuAtualizarResultado(ref.id, this.removeDigitosEspeciaisCnpj(result.valor));
            return result.valor;
          } else {
            this.inserirOuAtualizarResultado(ref.id, result.toString());
          }
        }
      }
    }
    return result?.toString();
  }

  inserirOuAtualizarResultado(idIndicador, resultado) {
    const existingItemIndex = this.insertIndicadorAtendimentoReferencial.findIndex((item) => item.idindicadorreferencial === idIndicador);
    const indicadorAtendimentoReferencialList = this.state.atendimentoCP.indicadorAtendimentoReferencialList;
    const indicadorExistente = indicadorAtendimentoReferencialList.find((item) => item.indicadorReferencial.id === idIndicador);
    const atendimentoId = this.props.atendimento.id;
    const medicoesAtendimento = this.medicoesIndicadores[atendimentoId] || {};
    const medicaoIndicador = medicoesAtendimento[idIndicador] || {};
    const medicaoInicial = parseFloat(medicaoIndicador.medicaoInicial);
    const medicaoFinal = parseFloat(medicaoIndicador.medicaoFinal);
    if (existingItemIndex !== -1) {
      this.insertIndicadorAtendimentoReferencial[existingItemIndex] = {
        ...this.insertIndicadorAtendimentoReferencial[existingItemIndex],
        id: indicadorExistente.id,
        resultado,
        medicaofinal: medicaoFinal ?? null,
        medicaoinicial: medicaoInicial ?? null,
      };
    } else {
      this.insertIndicadorAtendimentoReferencial.push({
        id: indicadorExistente.id,
        idatendimento: atendimentoId,
        idindicadorreferencial: idIndicador,
        resultado: resultado,
        medicaofinal: medicaoFinal ?? null,
        medicaoinicial: medicaoInicial ?? null,
      });
    }
    
    const indicadorAtualizado = {
      ...indicadorExistente,
      resultado,
      medicaoinicial: medicaoInicial ?? null,
      medicaofinal: medicaoFinal ?? null,
    };

    const updatedIndicadorAtendimentoReferencialList = indicadorAtendimentoReferencialList.map((item) =>
      item.indicadorReferencial.id === idIndicador ? { ...item, medicaoFinal: indicadorAtualizado.medicaofinal, medicaoinicial: indicadorAtualizado.medicaoinicial } : item
    );
  
    this.setState({
      atendimentoCP: {
        ...this.state.atendimentoCP,
        indicadorAtendimentoReferencialList: updatedIndicadorAtendimentoReferencialList,
      },
    });
  }

  handleCnpjValues = (cnpjValues) => {
    const cleanedCnpjs = cnpjValues.map((cnpj) => this.removeDigitosEspeciaisCnpj(cnpj));
    const newCnpjsValues = cleanedCnpjs.join(" ; ");

    this.setState(
      { cnpjValues: newCnpjsValues },
      () => {
        this.inserirOuAtualizarResultado(IndicadoresEnum.CNPJDaEmpresaValidadora, this.state.cnpjValues);
      }
    );
  };

  handleNumCnpjRegistered = (numCnpjsRegistered) => {
    this.setState({ numberOfCnpjsRegistered: numCnpjsRegistered }, () => {
      this.indicadorReferencialLines = this.indicadorReferencialList.map((indicador) => this.createLineRegistro(indicador));
    });
  };

  getCnpjValues = async () => {
    try {
      let indicadorAtendimentoReferencialBackNovo = [];
      const result = await newBackend.get(`/indicador-atendimento-referencial?filter.idatendimento=$eq:${this.props.atendimento.id}`);
      
      indicadorAtendimentoReferencialBackNovo = result.data.data;
  
      const cnpjListEmpresasValidadoras = indicadorAtendimentoReferencialBackNovo.find(
        (item) => item.idindicadorreferencial === IndicadoresEnum.CNPJDaEmpresaValidadora
      );
  
      if (cnpjListEmpresasValidadoras && cnpjListEmpresasValidadoras.resultado) {
        const cnpjList = cnpjListEmpresasValidadoras.resultado.split(" ; ").filter(Boolean);
        const numberOfCnpjsRegistered = cnpjList.length;
  
        this.setState({
          cnpjValues: cnpjListEmpresasValidadoras.resultado,
          numberOfCnpjsRegistered: numberOfCnpjsRegistered,
        });
      } else {
        this.setState({
          cnpjValues: "",
          numberOfCnpjsRegistered: 0,
        });
      }
    } catch (error) {
      console.error("Erro ao buscar os valores de CNPJ:", error);
  
      this.setState({
        cnpjValues: "",
        numberOfCnpjsRegistered: 0,
      });
  
      this.props.showToast("Erro ao carregar os valores de CNPJs. Tente novamente mais tarde.", "error");
    }
  };

  closeModalCnpj = () => {
    this.inserirOuAtualizarResultado(IndicadoresEnum.CNPJDaEmpresaValidadora, this.state.cnpjValues);
    this.setState({ openModalCNPJ: false })
  }

  removeDigitosEspeciaisCnpj = (cnpj) => {
    return cnpj.replace(/[^\d]+/g, "").replace(/^(\d{14}).*$/, "$1");
  };

  transformaObjetosInputs = (array) => {
    return array.reduce((acc, item) => {
      acc[item.nome] = { ...item };
      return acc;
    }, {});
  }

  render() {
    const icon = (img) => <img className={"tooltipIndicadores"} src={`./../../assets/icons/${img}.svg`} alt="" />;
    const action = this.isDN ? "Avaliar" : "Registrar";
    const inputLinesRender = this.createLineInputProgram(this.InputList, this.indicadorReferencialList);
    const { programaBaseNacional } = this.state;

    return (
      <Grid container>
        {programaBaseNacional && <IndicadoresAlert programa={programaBaseNacional} />}
        <Grid item xs={12}>
          <Grid container direction={"row"} className={"registar-anexo"} spacing={2}>
            <Grid item className={"lbl-arrow"}>
              {" "}
              <label className={"left"}> Resumo do Atendimento </label>{" "}
            </Grid>
            <Grid item className={"arrow-indicadores"}>
              {" "}
              {icon("arrow-registro-indicadores")}{" "}
            </Grid>
            <Grid item className={"lbl-arrow"}>
              {" "}
              <label className={"right"}>{action} Indicadores</label>{" "}
            </Grid>
          </Grid>
        </Grid>
        <SimpleModal
            isOpen={this.state.openModalCNPJ}
            disableClick={true}
            handleClose={this.closeModalCnpj}
            type={'info'}
            className={'modal-cnpj'}
        >
          <RegistroCNPJ 
            numberOfCnpjs={this.state.numberOfCnpjs}
            cnpjValues={this.state.cnpjValues}
            handleCnpjValues={this.handleCnpjValues}
            handleNumCnpjRegistered={this.handleNumCnpjRegistered}
            input={InputSmartFactoryEnum.CNPJDaEmpresaValidadora}
            showToast={this.props.showToast}
            showLoading={this.props.showLoading}
            closeLoading={this.props.closeLoading}
            closeModal={this.closeModalCnpj}
          />
        </SimpleModal>
        {inputLinesRender.length > 0 && (
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Table cellWidth={"15%"} lines={inputLinesRender} titles={this.registroTitlesInput} types={this.types} initialOrder={""} pagination={false}></Table>
          </Grid>
        )}
        {this.indicadorReferencialLines.length > 0 && (
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Table
              cellWidth={"15%"}
              lines={this.indicadorReferencialLines}
              titles={this.isDN ? this.registroIndicadoresInputTitleAvaliacao : this.registroIndicadoresInputTitleAvaliado}
              types={this.types}
              initialOrder={""}
              pagination={false}
            ></Table>
          </Grid>
        )}
        {this.indicadorReferencialLines.length > 0 && (
          <Grid item container xs={12} justify={"center"} style={{ marginTop: "2%" }}>
            <Grid item xs={2} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              <GenericButton
                color={"darkBlue-outline"}
                label={"Cancelar"}
                subClass={"basic-button"}
                click={() => {
                  this.backToResumo();
                }}
              />
            </Grid>
            <Grid item xs={2} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              <GenericButton color={"darkBlue"} label={action} subClass={"basic-button"} click={this.salvarRegistros} />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}
