import { Grid } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import "./../../components/colorPallete.scss";
import "./SGTSidebar.scss";

import { BASE_URL } from "./../../comm/API";
import { SyncRequest } from "./../../comm/APIsync";
import SGTLogoMenuIcon from "./SGTLogoMenuIcon";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0",
    ...theme.mixins.toolbar,
  },
}));

export default function MiniDrawer(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const checkIfOpen = () => {
    if (!open) {
      setOpen(true);
    } else {
      setSelectedIndex(-1);
      setOpen(false);
    }
  };

  const logout = () => {
    SyncRequest("get", BASE_URL + "rest/security/logout");
    sessionStorage.setItem("logado", false);
    return;
  };

  function handleToggle(index) {
    if (!open) {
      setOpen(true);
    }
    if (selectedIndex === index) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  }

  return (
    <div className={classes.root + (open ? " menu-open" : " menu-closed")}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(
          classes.drawer,
          {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          },
          "drawer-item"
        )}
        classes={{
          paper: clsx(
            {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            },
            "drawer-item"
          ),
        }}
        open={open}
      >
        <IconButton classes={{ root: "menuButton" }} className="menuButton" aria-label="Open drawer" onClick={checkIfOpen} edge="start">
          <SGTLogoMenuIcon isOpen={open} />
        </IconButton>

        <List disablePadding className={"icon-list"}>
          {props.menus.map((itemGrupo, index) => {
            if (itemGrupo.nomeGrupoTela === "Solicitações DN") {
              itemGrupo.itensTela = itemGrupo.itensTela.filter(
                item => !(item.comando === "cad/monitoramento-dn" && item.titulo === "Monitoramento DN")
              );
            }
            return itemGrupo.itensTela.length === 1 ? (
              <div key={"SGTSidebarDiv" + index}>
                <Link to={`/${itemGrupo.itensTela[0].comando}`} key={"SGTSidebarLink" + itemGrupo.itensTela[0]}>
                  <ListItem button onClick={handleToggle.bind(this, index)} selected={selectedIndex === index} className="sgt-sidebar-item">
                    <ListItemIcon>
                      <img className="sgt-sidebar-icon" src={process.env.PUBLIC_URL + "/assets/icons/" + itemGrupo.iconeGrupoTela.replace("icon-", "") + ".svg"} alt="" />
                    </ListItemIcon>
                    <ListItemText primary={`${itemGrupo.itensTela[0].titulo}`} className={"sgt-sidebar-text"} />
                  </ListItem>
                </Link>
              </div>
            ) : (
              <div key={"SGTSidebarDiv" + index}>
                <ListItem button onClick={handleToggle.bind(this, index)} selected={selectedIndex === index} className="sgt-sidebar-item">
                  <ListItemIcon>
                    <img className="sgt-sidebar-icon" src={process.env.PUBLIC_URL + "/assets/icons/" + itemGrupo.iconeGrupoTela.replace("icon-", "") + ".svg"} alt="" />
                  </ListItemIcon>
                  <ListItemText primary={`${itemGrupo.nomeGrupoTela}`} className={"sgt-sidebar-text"} />
                  {selectedIndex === index ? <ExpandLess color="disabled" /> : <ExpandMore color="disabled" />}
                </ListItem>

                <Collapse in={selectedIndex === index} timeout="auto" key={"SGTSidebarListCollapse" + index} unmountOnExit>
                  <List disablePadding component="div">
                    {itemGrupo.itensTela.map((itemTela, indexItemTela) => (
                      <Link to={`/${itemTela.comando}`} key={"SGTSidebarLink" + indexItemTela}>
                        <ListItem button className="sgt-sidebar-subitem">
                          <ListItemText primary={`${itemTela.titulo}`} />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              </div>
            );
          })}
        </List>

        <Grid id={"sidebar-bottom"}>
          {props.usuarioConectado != null ? (
            <Link to={""} id="sidebar-photo">
              <IconButton className="photo-button" aria-label="Logout">
                {props.usuarioConectado.urlFoto != null ? (
                  <img alt="" src={BASE_URL + props.usuarioConectado.urlFoto} className={"usuario-photo"} />
                ) : (
                  <img alt="" src={process.env.PUBLIC_URL + "user-login.png"} className={"usuario-photo"} />
                )}
              </IconButton>
              <Grid className={"user-info"}>
                <span>
                  <b>{props.usuarioConectado.nome}</b>
                  <br></br>
                  {props.usuarioConectado.colaborador != null && props.usuarioConectado.colaborador.isAtivo ? "UO" : props.usuarioConectado.unidade.tipoUnidade.sigla} -{" "}
                  {props.usuarioConectado.unidade.municipio.unidadeFederativa.sigla}
                </span>
              </Grid>
            </Link>
          ) : null}
          <Grid id={"sidebar-buttons"}>
            <Link to={"/cad/sistema/meusDados"} className="link">
              <IconButton className="profile-button" aria-label="Logout">
                <img className="sgt-sidebar-icon" src={process.env.PUBLIC_URL + "/assets/icons/profileConfig.svg"} alt="" />
              </IconButton>
            </Link>
            <Link to={"/"} className="link">
              <IconButton className="logout-button" aria-label="Logout" onClick={logout}>
                <img className="sgt-sidebar-icon" src={process.env.PUBLIC_URL + "/assets/icons/logout.svg"} alt="" />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
}
