import axios from 'axios';

export default function DownloadFilesNewBackend (urlFile, nomeArquivo) {
    const method = 'GET';

    axios.request({ 
        url: urlFile,
        method, 
        responseType: 'blob',
    }).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', nomeArquivo);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }).catch(error => {
        console.error("Erro ao baixar o arquivo:", error);
    });
}