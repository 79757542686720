export const TipoInputEnum = {
    ValorUnico: 1,
    MedicaoInicial: 2,
    MedicaoFinal: 3,
    SmartFactory: 4,
    NivelTransformacao: 5,
    PrioridadeTransformacao: 6,
    FaixaTransformacao: 7,
    TecnologiaTransformacao: 8,
    IncluiTransformacao: 9,
    InvestimentoTransformacao: 10,
    Aprovacao: 11,
    Cnpj: 12,
    ValorInteiro: 15
};